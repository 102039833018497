import React from 'react';
import {
  Typography,
  Divider,
  Grid,
  Button

} from '@material-ui/core';

import Tabs from './contentAreaComponents/Tabs/Tabs.js';
import Questionnaire from './contentAreaComponents/Questionnaire';
import ProfitLossPotential from './contentAreaComponents/ProfitLossPotential';
import PercentileComparison from './contentAreaComponents/PercentileComparison';

class DashboardContentArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: 'Churn',
      revenue:this.props.revenueData,
      questionOne:null,
      questionTwo:null,
      questionThree:null,
      questionSelected:1,
      churnTotal:0,
      calculatedChurnScore:0,
      score: 0,
      tab1: 'in-progress-tab',
      tab2: '',
      tab3: '',
      tab4: '',
      tab5: '',
      tab1Completed: false,
      tab2Completed: false,
      tab3Completed: false,
      tab4Completed: false,
      tab5Completed: false,

      tab1Image : '/publicImages/in-progress.png',
      tab2Image : '/publicImages/not-started.png',
      tab3Image : '/publicImages/not-started.png',
      tab4Image : '/publicImages/not-started.png',
      tab5Image : '/publicImages/not-started.png',

      congratulationReplacementContent:false
    }

    this.setToCrossSell = this.setToCrossSell.bind(this)
    this.updateChurnTotal = this.updateChurnTotal.bind(this)
    this.updateCrossSellTotal = this.updateCrossSellTotal.bind(this)
    this.updateCongratulationsReplacementContent = this.updateCongratulationsReplacementContent.bind(this)
  }

  nextQuestion = () =>{
    let setQuestion = (((this.state.questionSelected)%3)+1)
    this.setState({questionSelected:setQuestion})
  }
  previousQuestion = () =>{

    let setQuestion = this.state.questionSelected-1
    if(setQuestion < 1){
      setQuestion = 3
    }
    this.setState({questionSelected:setQuestion})
  }



  updateCongratulationsReplacementContent = (updateTruth) =>{
    //alert(updateTruth)
      this.setState({congratulationReplacementContent:updateTruth})
  }

  selectChurnTab = () =>{
    if(this.props.tabSectionComplete <1){
    }
    else if(this.props.tabSectionComplete <2){
      this.setState({tab1Image:'/publicImages/in-progress.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
    }

    else if(this.props.tabSectionComplete <3){
      this.setState({tab1Image:'/publicImages/in-progress.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
    }
    else if(this.props.tabSectionComplete <3){
      this.setState({tab1Image:'/publicImages/in-progress.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})

    }
    else if(this.props.tabSectionComplete <4){
      this.setState({tab1Image:'/publicImages/in-progress.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
    }
    else{
      this.setState({congratulationReplacementContent:false})
      this.setState({tab1Image:'/publicImages/in-progress.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})
    }
     this.setToChurn()
     this.setState({questionSelected:1})
     this.setState({tab1:"in-progress-tab"})
     this.setState({tab2:""})
     this.setState({tab3:""})
     this.setState({tab4:""})
     this.setState({tab5:""})

    if ( this.state.tab4Completed ) {
      this.setState({tab5Completed: true})
    }
  }
   selectCrossSellTab = () =>{

    if(this.props.tabSectionComplete <2){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/in-progress.png'})
    }
    else if(this.props.tabSectionComplete <3){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/in-progress.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
    }
    else if(this.props.tabSectionComplete <3){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/in-progress.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})

    }
    else if(this.props.tabSectionComplete <4){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/in-progress.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
    }
    else{
      this.setState({congratulationReplacementContent:false})
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/in-progress.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})
    }

    this.setToCrossSell()
    this.setState({questionSelected:1})
    this.setState({tab1Completed: true})
    this.setState({tab1:""})
    this.setState({tab2:"in-progress-tab"})
    this.setState({tab3:""})
    this.setState({tab4:""})
    this.setState({tab5:""})

    if ( this.state.tab4Completed ) {
      this.setState({tab5Completed: true})
    }

  }

  selectInconsistencyTab = () =>{
    if(this.props.tabSectionComplete <3){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/in-progress.png'})
    }
    else if(this.props.tabSectionComplete <4){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/in-progress.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})

    }
    else if(this.props.tabSectionComplete <5){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/in-progress.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})
    }
    else{
      this.setState({congratulationReplacementContent:false})
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/in-progress.png'})
      this.setState({tab4Image:'/publicImages/edit.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})


    }

   this.setToInconsistency()
   this.setState({questionSelected:1})
   this.setState({tab2Completed: true})
   this.setState({tab1:""})
   this.setState({tab2:""})
   this.setState({tab3:"in-progress-tab"})
   this.setState({tab4:""})
   this.setState({tab5:""})

   if ( this.state.tab4Completed ) {
    this.setState({tab5Completed: true})
  }

  }

  selectMisalignmentTab = () =>{

    if(this.props.tabSectionComplete <4){
        this.setState({tab1Image:'/publicImages/edit.png'})
        this.setState({tab2Image:'/publicImages/edit.png'})
        this.setState({tab3Image:'/publicImages/edit.png'})
        this.setState({tab4Image:'/publicImages/in-progress.png'})


      }

    else if(this.props.tabSectionComplete <5){
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/in-progress.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})

    }
    else{
      this.setState({congratulationReplacementContent:false})
      this.setState({tab1Image:'/publicImages/edit.png'})
      this.setState({tab2Image:'/publicImages/edit.png'})
      this.setState({tab3Image:'/publicImages/edit.png'})
      this.setState({tab4Image:'/publicImages/in-progress.png'})
      this.setState({tab5Image:'/publicImages/edit.png'})
    }


    this.setToMisalignment()
    this.setState({questionSelected:1})
    this.setState({tab3Completed: true})
    this.setState({tab1:""})
    this.setState({tab2:""})
    this.setState({tab3:""})
    this.setState({tab4:"in-progress-tab"})
    this.setState({tab5:""})

    if ( this.state.tab4Completed ) {
      this.setState({tab5Completed: true})
    }
  }

  selectInefficiencyTab = () =>{
    this.setState({congratulationReplacementContent:false})
    this.setState({tab1Image:'/publicImages/edit.png'})
    this.setState({tab2Image:'/publicImages/edit.png'})
    this.setState({tab3Image:'/publicImages/edit.png'})
    this.setState({tab4Image:'/publicImages/edit.png'})
    this.setState({tab5Image:'/publicImages/in-progress.png'})
    this.setToInefficiency()
    this.setState({questionSelected:1})
    this.setState({tab4Completed: true})
    this.setState({tab1:""})
    this.setState({tab2:""})
    this.setState({tab3:""})
    this.setState({tab4:""})
    this.setState({tab5:"in-progress-tab"})
  }


  updateChurnTotal = (churnTotalData) =>{
    this.setState({ churnTotal: churnTotalData });

  }
  updateCrossSellTotal = (churnTotalData) =>{
      this.setState({ churnTotal: churnTotalData }, function() {
        let tempTotal = (Number(churnTotalData)/100)*(Number(this.state.revenue))
        this.setState({calculatedChurnScore:tempTotal.toFixed(2)})
      });
    }

  setToCrossSell = () =>{
    this.setState({activeCategory:"Cross-Sell"})
  }
  setToChurn = () =>{
    this.setState({activeCategory:"Churn"})
  }
  setToInconsistency = () =>{
    this.setState({activeCategory:"Inconsistency"})
  }
  setToMisalignment = () =>{
    this.setState({activeCategory:"Misalignment"})
  }
  setToInefficiency = () =>{
    this.setState({activeCategory:"Inefficiency"})
  }

//this is where the churn percent will need to go

  render() {
    let generateReportAllowed =true
    let genStyle = {
      marginTop:"30px",
      borderColor:"orange",
      borderWidth: "2px",
      borderStyle:"solid",
      backgroundColor:"#fff"

    }
    if(this.props.tabSectionComplete==5){
      generateReportAllowed = false
      genStyle = {
        marginTop:"30px",
        borderColor:"orange",
        borderWidth: "2px",
        borderStyle:"solid",
        backgroundColor:"#fff"
      }
    }

    return(
      <>
        <div id='dashboard-content-area-inner-wrapper'>

          <div id="confetti-wrapper"></div>
          <Typography variant="h1">
            {this.props.companyName} Benchmark Report
          </Typography>

          <Divider/>

          <Grid container spacing={3} style={{marginTop: '24px'}}>
            <Grid item xs={12} lg={8}>
              <Tabs
                churnHighLow ={this.props.churnHighLow}
                crossSellHighLow ={this.props.crossSellHighLow}
                inconsistencyHighLow ={this.props.inconsistencyHighLow}
                misalignmentHighLow ={this.props.misalignmentHighLow}
                inefficiencyHighLow ={this.props.inefficiencyHighLow}

                tab1Completed={this.state.tab1Completed}
                tab2Completed={this.state.tab2Completed}
                tab3Completed={this.state.tab3Completed}
                tab4Completed={this.state.tab4Completed}
                tab5Completed={this.state.tab5Completed}

                setStateToCrossSell = {this.setToCrossSell}
                setStateToChurn = {this.setToChurn}
                setStateToInconsistency = {this.setToInconsistency}
                setStateToMisalignment= {this.setToMisalignment}
                setStateToInefficiency = {this.setToInefficiency}
                categorySelected={this.state.activeCategory}

                selectChurnTab = {this.selectChurnTab}
                selectCrossSellTab = {this.selectCrossSellTab}
                selectInconsistencyTab = {this.selectInconsistencyTab}
                selectMisalignmentTab = {this.selectMisalignmentTab}
                selectInefficiencyTab = {this.selectInefficiencyTab}
                industry={this.props.industry}

                churnScore  = {this.props.churnScore}
                crossSellScore = {this.props.crossSellScore}
                inconsistencyScore = {this.props.inconsistencyScore}
                misalignmentScore = {this.props.misalignmentScore}
                inefficiencyScore = {this.props.inefficiencyScore}

                tabSectionComplete = {this.props.tabSectionComplete}

                tab1 = {this.state.tab1}
                tab2 = {this.state.tab2}
                tab3 = {this.state.tab3}
                tab4 = {this.state.tab4}
                tab5 = {this.state.tab5}

                tab1Image = {this.state.tab1Image}
                tab2Image = {this.state.tab2Image}
                tab3Image = {this.state.tab3Image}
                tab4Image = {this.state.tab4Image}
                tab5Image = {this.state.tab5Image}
              />

              <div style={{position: 'relative'}}>

                <Questionnaire
                  questionSelected = {this.state.questionSelected}
                  nextQuestion = {this.nextQuestion}
                  previousQuestion = {this.previousQuestion}

                  formData={this.props.formData}
                  returnShareLink={this.props.returnShareLink}

                  tab1 = {this.state.tab1}
                  tab2 = {this.state.tab2}
                  tab3 = {this.state.tab3}
                  tab4 = {this.state.tab4}
                  tab5 = {this.state.tab5}
                  selectPageFromRouting = {this.props.selectPageFromRouting}
                  updateCongratulationsReplacementContent = {this.updateCongratulationsReplacementContent}
                  congratulationReplacementContent = {this.state.congratulationReplacementContent}

                  churnHighLow ={this.props.churnHighLow}
                  crossSellHighLow ={this.props.crossSellHighLow}
                  inconsistencyHighLow ={this.props.inconsistencyHighLow}
                  misalignmentHighLow ={this.props.misalignmentHighLow}
                  inefficiencyHighLow ={this.props.inefficiencyHighLow}
                  updateHighLow = {this.props.updateHighLow}

                  industry={this.props.industry}

                  selectChurnTab = {this.selectChurnTab}
                  selectCrossSellTab = {this.selectCrossSellTab}
                  selectInconsistencyTab = {this.selectInconsistencyTab}
                  selectMisalignmentTab = {this.selectMisalignmentTab}
                  selectInefficiencyTab = {this.selectInefficiencyTab}

                  setStateToCrossSell = {this.setToCrossSell}
                  setStateToChurn = {this.setToChurn}
                  setStateToInconsistency = {this.setToInconsistency}
                  setStateToMisalignment= {this.setToMisalignment}
                  setStateToInefficiency = {this.setToInefficiency}
                  categorySelected={this.state.activeCategory}

                  displayTotalRevenueAndMarginLoss={this.props.displayTotalRevenueAndMarginLoss}
                  categorySelected={this.state.activeCategory}
                  updateDashChurnTotal = {this.updateChurnTotal}

                  tabSectionComplete = {this.props.tabSectionComplete}

                  updateChurnProfitLoss = {this.props.updateChurnProfitLoss}
                  updateCrossSellProfitLoss = {this.props.updateCrossSellProfitLoss}
                  updateInconsistencyProfitLoss = {this.props.updateInconsistencyProfitLoss}
                  updateMisalignmentProfitLoss = {this.props.updateMisalignmentProfitLoss}
                  updateInefficiencyProfitLoss = {this.props.updateInefficiencyProfitLoss}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              <ProfitLossPotential
                churnHighLow ={this.props.churnHighLow}
                crossSellHighLow ={this.props.crossSellHighLow}
                inconsistencyHighLow ={this.props.inconsistencyHighLow}
                misalignmentHighLow ={this.props.misalignmentHighLow}
                inefficiencyHighLow ={this.props.inefficiencyHighLow}
                currency={this.props.currency}

                totalAnnualMarginLoss={this.props.totalAnnualMarginLoss}
                totalAnnualRevenueLoss={this.props.totalAnnualRevenueLoss}
                churnScore  = {this.props.churnScore}
                crossSellScore = {this.props.crossSellScore}
                inconsistencyScore = {this.props.inconsistencyScore}
                misalignmentScore = {this.props.misalignmentScore}
                inefficiencyScore = {this.props.inefficiencyScore}
                category={this.state.activeCategory}
              />
              <PercentileComparison
                churnProfitLoss = {this.props.churnProfitLoss}
                crossSellProfitLoss = {this.props.crossSellProfitLoss}
                inconsistencyProfitLoss = {this.props.inconsistencyProfitLoss}
                misalignmentProfitLoss = {this.props.misalignmentProfitLoss}
                inefficiencyProfitLoss = {this.props.inefficiencyProfitLoss}
                currency={this.props.currency}

                churnScore = {this.props.churnScore}
                crossSellScore = {this.props.crossSellScore}
                inconsistencyScore = {this.props.inconsistencyScore}
                misalignmentScore = {this.props.misalignmentScore}
                inefficiencyScore = {this.props.inefficiencyScore}

                churnHighLow ={this.props.churnHighLow}
                crossSellHighLow ={this.props.crossSellHighLow}
                inconsistencyHighLow ={this.props.inconsistencyHighLow}
                misalignmentHighLow ={this.props.misalignmentHighLow}
                inefficiencyHighLow ={this.props.inefficiencyHighLow}

                setCalculatedChurnScore = {this.state.calculatedChurnScore}
                industry={this.props.industry}
                category={this.state.activeCategory}
                score={this.state.score}
              />

              <Button
                disabled={generateReportAllowed}
                style={genStyle}
                onClick = {()=>{this.props.selectPageFromRouting("Results")}}
              >Generate My Report</Button>

            </Grid>

          </Grid>
        </div>
      </>
    );
  }
}

export default DashboardContentArea;
