import React from 'react';

class Graph extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {

    let lowPercent = 0
    let medianPercent = 0
    let highPercent = 0




    if ( this.props.industry == 'Manufacturing' ) {
      if ( this.props.category == 'Churn' ) {
        lowPercent= 4.28
        medianPercent= 8.86
        highPercent= 16.12

      } else if ( this.props.category == 'Cross-Sell' ) {
        lowPercent= 4.69
        medianPercent= 10.78
        highPercent= 20.76
      } else if ( this.props.category == 'Inconsistency' ) {
        lowPercent= 1.05
        medianPercent= 4.06
        highPercent= 8.8
      } else if ( this.props.category == 'Misalignment' ) {
        lowPercent= .51
        medianPercent= 2.12
        highPercent= 7.47
      } else if ( this.props.category == 'Inefficiency' ) {
        lowPercent= .76
        medianPercent= 1.58
        highPercent= 2.4
      } // end if ( this.props.category == 'Churn' )
    } else if ( this.props.industry == 'Distribution' ) {
      if ( this.props.category == 'Churn' ) {
        lowPercent= 4.33
        medianPercent= 9.08
        highPercent= 15.07
      } else if ( this.props.category == 'Cross-Sell' ) {
        lowPercent= 2.92
        medianPercent= 7.09
        highPercent= 13.4
      } else if ( this.props.category == 'Inconsistency' ) {
        lowPercent= 1.55
        medianPercent= 3.71
        highPercent= 7.57
      } else if ( this.props.category == 'Misalignment' ) {
        lowPercent= .58
        medianPercent= 2.35
        highPercent= 5.73
      } else if ( this.props.category == 'Inefficiency' ) {
        lowPercent= .76
        medianPercent= 1.58
        highPercent= 2.4
      } // end if ( this.props.category == 'Churn' )
    } else if ( this.props.industry == 'Other' ) {
      if ( this.props.category == 'Churn' ) {
        lowPercent= 4.45
        medianPercent= 9.39
        highPercent= 16.07
      } else if ( this.props.category == 'Cross-Sell' ) {
        lowPercent= 3.19
        medianPercent= 7.74
        highPercent= 15.67
      } else if ( this.props.category == 'Inconsistency' ) {
        lowPercent= 1.37
        medianPercent= 3.96
        highPercent= 8.12
      } else if ( this.props.category == 'Misalignment' ) {
        lowPercent= .52
        medianPercent= 2.09
        highPercent= 6.58
      } else if ( this.props.category == 'Inefficiency' ) {
        lowPercent= .76
        medianPercent= 1.58
        highPercent= 2.4
      } // end if ( this.props.category == 'Churn' )
    }

    const plotOnGraph = (percent) => {
      return (percent - lowPercent) / (highPercent - lowPercent) * 100;
    };

    let scoreClass = "percent-score your-score";
    if ( Number(this.props.score) < Number(medianPercent) ) {
      scoreClass = "percent-score your-score low-score";
    } else if ( Number(this.props.score) >= Number(medianPercent) ) {
      scoreClass = "percent-score your-score high-score";
    }

    let showStyle = {}
    if (this.props.score == 0) {
      showStyle = {
        display: "none",
        left: 'calc(' + plotOnGraph(this.props.score) + '% - 95px)'
      }
    } else {
      showStyle = {
        display: "block",
        left: 'calc(' + plotOnGraph(this.props.score) + '% - 95px)'
      }
    }


    let scoreText = "Your score is " + this.props.score.toFixed(2) + "%";

    if ( this.props.category == 'Churn' ) {
      scoreText = "Your revenue loss is " + this.props.score.toFixed(2) + "%";
    } else if ( this.props.category == 'Cross-Sell' ) {
      scoreText = "Your revenue loss is " + this.props.score.toFixed(2) + "%";
    } else if ( this.props.category == 'Inconsistency' ) {
      scoreText = "Your margin loss is " + this.props.score.toFixed(2) + "%";
    } else if ( this.props.category == 'Misalignment' ) {
      scoreText = "Your margin loss is " + this.props.score.toFixed(2) + "%";
    } else if ( this.props.category == 'Inefficiency' ) {
      scoreText = "Your margin loss is " + this.props.score.toFixed(2) + "%";
    }
    

    return(
        <div className="percentile-graph">
          <div className="percentile-range">

            <div
              className={scoreClass}
              style={showStyle}
            >
              {scoreText}
            </div>

            <div className="percent-bar low-percent">
              <div className="percent-number">{lowPercent}%</div>
            </div>

            <div
              className="percent-bar median-percent"
              style={{left: 'calc(' + plotOnGraph(medianPercent) + '% - 2px)'}}
            >
              <div className="percent-number median-percent">{medianPercent}%</div>
            </div>

            <div className="percent-bar high-percent">
              <div className="percent-number">{highPercent}%</div>
            </div>



            <div className="percentile-dotted-line-indicators">

              <div className="percentile-dotted-lines">
                <div className="median-dotted-line" style={{left: plotOnGraph(medianPercent) + '%'}}></div>
              </div>

              <div className="percentile-labels">
                <div className="tenth-percentile">10th percentile</div>
                <div className="median-percentile" style={{left: plotOnGraph(medianPercent) + '%'}}>Median</div>
                <div className="ninetieth-percentile">90th percentile</div>
              </div>

            </div>
            

          </div>
        </div>
    );
  }
}

export default Graph;
