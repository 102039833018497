import React from 'react';
import '../../styles/Dashboard.css';
import sidebarBackground from '../../images/dashboard-sidebar-bg.jpg';
import {
  Grid
} from '@material-ui/core';


import Sidebar from '../SidebarRouting.js';
import ContentArea from '../ContentAreaRouting.js';

function Dashboard(props) {

  return(
    <Grid container style={{backgroundColor: '#f9f9fc'}}>

      <Grid item className="sidebar" only='md'  md={2} style={{backgroundImage: 'url(' + sidebarBackground +')'}}>
        <Sidebar
          page="dashboard"
        />
      </Grid>

      <Grid item className="content-area" id="dashboard-content-area" xs={12} md={10}>
        <ContentArea
          page="dashboard"
          selectPageFromRouting = {props.selectPageFromRouting}

          updateChurnProfitLoss = {props.updateChurnProfitLoss}
          updateCrossSellProfitLoss = {props.updateCrossSellProfitLoss}
          updateInconsistencyProfitLoss = {props.updateInconsistencyProfitLoss}
          updateMisalignmentProfitLoss = {props.updateMisalignmentProfitLoss}
          updateInefficiencyProfitLoss = {props.updateInefficiencyProfitLoss}

          totalAnnualRevenueLoss = {props.totalAnnualRevenueLoss}
          totalAnnualMarginLoss = {props.totalAnnualMarginLoss}

          displayTotalRevenueAndMarginLoss = {props.displayTotalRevenueAndMarginLoss}

          tabSectionComplete = {props.tabSectionComplete}

          industry={props.industry}
          currency={props.currency}

          formData={props.formData}
          returnShareLink={props.returnShareLink}

          churnProfitLoss = {props.churnProfitLoss}
          crossSellProfitLoss = {props.crossSellProfitLoss}
          inconsistencyProfitLoss = {props.inconsistencyProfitLoss}
          misalignmentProfitLoss = {props.misalignmentProfitLoss}
          inefficiencyProfitLoss = {props.inefficiencyProfitLoss}

          churnHighLow ={props.churnHighLow}
          crossSellHighLow ={props.crossSellHighLow}
          inconsistencyHighLow ={props.inconsistencyHighLow}
          misalignmentHighLow ={props.misalignmentHighLow}
          inefficiencyHighLow ={props.inefficiencyHighLow}
          updateHighLow = {props.updateHighLow}

          churnScore  = {props.churnScore}
          crossSellScore = {props.crossSellScore}
          inconsistencyScore = {props.inconsistencyScore}
          misalignmentScore = {props.misalignmentScore}
          inefficiencyScore = {props.inefficiencyScore}

          companyName={props.companyName}
          revenueData={props.revenueData}
        />
      </Grid>
    </Grid>
  );
}
export default Dashboard;
