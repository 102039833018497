import React from 'react';

import {
  Typography,
  Button
} from '@material-ui/core';

import MedalIcon from '../../../../images/medal.js';

function Congratulations(props) {
  
  return(
    <div id="congratulations-content">
      <div>
        <MedalIcon/>

        <Typography variant="h2">
          Complete!
        </Typography>

        <p className="bold-subtitle">
          Your full assessment is ready.
        </p>

        <p style={{marginBottom: '0'}}>
          Generate your report below to see how your company compares to the Zilliant 2020 Global Benchmark Report and view content tailored to your results.
        </p>

        <Button
          id="generate-my-report-button"
          variant="contained"
          onClick = {()=>{props.selectPageFromRouting("Results")}}
        >Generate my report</Button>
      </div>
    </div>
  );
}

export default Congratulations;
