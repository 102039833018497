import React from 'react';
import '../../styles/Results.css';
import {
  Grid
} from '@material-ui/core';
import Sidebar from '../SidebarRouting.js';
import ContentArea from '../ContentAreaRouting.js';

class Results extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTimeout(() => {
      var drift = document.getElementById('drift-widget-container');
      if ( !drift ) { return; }
      drift.classList.add('show-drift-widget');
    }, 3000);
  }

  render(props) {

    return(
      <Grid container style={{backgroundColor: '#f9f9fc'}}>

        <Grid item className="sidebar" xs={12}  md={4}>
          <Sidebar
            page="results"
            generateShareLink={this.props.generateShareLink}
            companyName={this.props.companyName}
            industry={this.props.industry}
          />
        </Grid>

        <Grid item className="content-area" id="results-content-area" xs={12} md={8}>
          <ContentArea
            page="results"
            industry={this.props.industry}
            subIndustry={this.props.subIndustry}
            generateShareLink={this.props.generateShareLink}
            companyName={this.props.companyName}
            totalProfitLoss={this.props.totalProfitLoss}
            churnProfitLoss = {this.props.churnProfitLoss}
            crossSellProfitLoss = {this.props.crossSellProfitLoss}
            inconsistencyProfitLoss = {this.props.inconsistencyProfitLoss}
            misalignmentProfitLoss = {this.props.misalignmentProfitLoss}
            inefficiencyProfitLoss = {this.props.inefficiencyProfitLoss}

            churnHighLow ={this.props.churnHighLow}
            crossSellHighLow ={this.props.crossSellHighLow}
            inconsistencyHighLow ={this.props.inconsistencyHighLow}
            misalignmentHighLow ={this.props.misalignmentHighLow}
            inefficiencyHighLow ={this.props.inefficiencyHighLow}
            currency={this.props.currency}

            revenue={this.props.revenue}
            formData={this.props.formData}
            updateContactFormData={this.props.updateContactFormData}
            returnShareLink={this.props.returnShareLink}

            churnScore  = {this.props.churnScore}
            crossSellScore = {this.props.crossSellScore}
            inconsistencyScore = {this.props.inconsistencyScore}
            misalignmentScore = {this.props.misalignmentScore}
            inefficiencyScore = {this.props.inefficiencyScore}

            totalAnnualRevenueLoss = {this.props.totalAnnualRevenueLoss}
            totalAnnualMarginLoss = {this.props.totalAnnualMarginLoss}

          />
        </Grid>

      </Grid>
    );
  }
}

export default Results;
