import React from 'react';
import DashboardContentArea from './ContentArea/DashboardContentArea.js';
import HomeContentArea from './ContentArea/HomeContentArea.js';
import ResultsContentArea from './ContentArea/ResultsContentArea.js';

class ContentArea extends React.Component {
  constructor(props) {
    super();
  }

  render(){
    switch(this.props.page) {
      case 'home':
        return(
          <HomeContentArea
            selectPageFromRouting={this.props.selectPageFromRouting}
            updateFormData={this.props.updateFormData}
            updateIndustry={this.props.updateIndustry}
            industry={this.props.industry}
            homeContentState={this.props.homeContentState}
          />
        );
        break;
      case 'dashboard':
        return(<>
          <DashboardContentArea
            updateChurnProfitLoss = {this.props.updateChurnProfitLoss}
            updateCrossSellProfitLoss = {this.props.updateCrossSellProfitLoss}
            updateInconsistencyProfitLoss = {this.props.updateInconsistencyProfitLoss}
            updateMisalignmentProfitLoss = {this.props.updateMisalignmentProfitLoss}
            updateInefficiencyProfitLoss = {this.props.updateInefficiencyProfitLoss}
            industry={this.props.industry}
            currency = {this.props.currency}

            formData={this.props.formData}
            returnShareLink={this.props.returnShareLink}

            tabSectionComplete = {this.props.tabSectionComplete}

            churnProfitLoss = {this.props.churnProfitLoss}
            crossSellProfitLoss = {this.props.crossSellProfitLoss}
            inconsistencyProfitLoss = {this.props.inconsistencyProfitLoss}
            misalignmentProfitLoss = {this.props.misalignmentProfitLoss}
            inefficiencyProfitLoss = {this.props.inefficiencyProfitLoss}

            churnScore  = {this.props.churnScore}
            crossSellScore = {this.props.crossSellScore}
            inconsistencyScore = {this.props.inconsistencyScore}
            misalignmentScore = {this.props.misalignmentScore}
            inefficiencyScore = {this.props.inefficiencyScore}

            churnHighLow ={this.props.churnHighLow}
            crossSellHighLow ={this.props.crossSellHighLow}
            inconsistencyHighLow ={this.props.inconsistencyHighLow}
            misalignmentHighLow ={this.props.misalignmentHighLow}
            inefficiencyHighLow ={this.props.inefficiencyHighLow}
            updateHighLow = {this.props.updateHighLow}

            totalAnnualRevenueLoss = {this.props.totalAnnualRevenueLoss}
            totalAnnualMarginLoss = {this.props.totalAnnualMarginLoss}

            selectPageFromRouting = {this.props.selectPageFromRouting}

            displayTotalRevenueAndMarginLoss = {this.props.displayTotalRevenueAndMarginLoss}

            companyName={this.props.companyName}
            revenueData={this.props.revenueData}
          />
          </>
        );
        break;
      case 'results':
        return(
          <>
          <ResultsContentArea
            generateShareLink={this.props.generateShareLink}
            returnShareLink={this.props.returnShareLink}

            companyName={this.props.companyName}
            formData={this.props.formData}
            updateContactFormData={this.props.updateContactFormData}
          
            totalProfitLoss={this.props.totalProfitLoss}
            churnProfitLoss = {this.props.churnProfitLoss}
            crossSellProfitLoss = {this.props.crossSellProfitLoss}
            inconsistencyProfitLoss = {this.props.inconsistencyProfitLoss}
            misalignmentProfitLoss = {this.props.misalignmentProfitLoss}
            inefficiencyProfitLoss = {this.props.inefficiencyProfitLoss}

            revenue = {this.props.revenue}
            currency = {this.props.currency}
            industry={this.props.industry}
            subIndustry={this.props.subIndustry}

            churnScore  = {this.props.churnScore}
            crossSellScore = {this.props.crossSellScore}
            inconsistencyScore = {this.props.inconsistencyScore}
            misalignmentScore = {this.props.misalignmentScore}
            inefficiencyScore = {this.props.inefficiencyScore}


            churnHighLow ={this.props.churnHighLow}
            crossSellHighLow ={this.props.crossSellHighLow}
            inconsistencyHighLow ={this.props.inconsistencyHighLow}
            misalignmentHighLow ={this.props.misalignmentHighLow}
            inefficiencyHighLow ={this.props.inefficiencyHighLow}


            companyName={this.props.companyName}


            totalAnnualRevenueLoss = {this.props.totalAnnualRevenueLoss}
            totalAnnualMarginLoss = {this.props.totalAnnualMarginLoss}
          />
          </>
        );
        break;
      default:
        return(
          <DashboardContentArea
            companyName={this.props.companyName}
          />
        );
      }
  }
}

export default ContentArea;
