import React from 'react';
import {
  Typography,
  Grid,
  Divider
} from '@material-ui/core';

import FormattedNumber from '../../../FormattedNumber.js';

import GraphSelect from './GraphSelect.js';

function PercentileComparison(props) {
  let profitLoss = 0;
  let highLowClass = ''
  let selectHighLow = ''


  if(props.category=='Churn'){
    profitLoss = props.churnProfitLoss
    selectHighLow = props.churnHighLow
    if(selectHighLow=="High"){
      selectHighLow = "High"
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      selectHighLow = "Low"
      highLowClass = 'category-score-text category-score-low'
    }
    else {

    }
  }
  else if(props.category=='Cross-Sell'){
    profitLoss = props.crossSellProfitLoss
    selectHighLow = props.crossSellHighLow
    if(selectHighLow=="High"){
      selectHighLow = "High"
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      selectHighLow = "Low"
      highLowClass = 'category-score-text category-score-low'
    }
    else {

    }
  }
  else if(props.category=='Inconsistency'){

    profitLoss = props.inconsistencyProfitLoss
    selectHighLow = props.inconsistencyHighLow
    if(selectHighLow=="High"){
      selectHighLow = "High"
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      selectHighLow = "Low"
      highLowClass = 'category-score-text category-score-low'
    }
    else {

    }
  }
  else if(props.category=='Misalignment'){
    profitLoss = props.misalignmentProfitLoss
    selectHighLow = props.misalignmentHighLow
    if(selectHighLow=="High"){
      selectHighLow = "High"
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      selectHighLow = "Low"
      highLowClass = 'category-score-text category-score-low'
    }
    else {

    }
  }
  else if(props.category=='Inefficiency'){
    profitLoss = props.inefficiencyProfitLoss
    selectHighLow = props.inefficiencyHighLow
    if(selectHighLow=="High"){
      selectHighLow = "High"
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      selectHighLow = "Low"
      highLowClass = 'category-score-text category-score-low'
    }
    else {

    }
  }
  // churnHighLow ={this.props.churnHighLow}
  // crossSellHighLow ={this.props.crossSellHighLow}
  // inconsistencyHighLow ={this.props.inconsistencyHighLow}
  // misalignmentHighLow ={this.props.misalignmentHighLow}
  // inefficiencyHighLow ={this.props.inefficiencyHighLow}

  let profitLossCopy = 'Profit Loss Potential';
  let percentileComparisonCopy = 'Percentile Comparison';
  let descriptionText = 'The higher the percentile, the higher the annual margin loss';
  let categoryText = props.category + ' Results';

  if ( props.category == 'Churn' ) {
    categoryText = 'Customer Churn Results';
    profitLossCopy = 'Revenue Loss From Customer Churn';
    percentileComparisonCopy = 'Customer Churn Percentile Comparison';
    descriptionText = 'The higher the percentile, the higher the annual revenue loss';
  } else if ( props.category == 'Cross-Sell' ) {
    categoryText = 'Missed Cross-Sell Results';
    profitLossCopy = 'Revenue Loss From Missed Cross-Sell';
    percentileComparisonCopy = 'Missed Cross-Sell Percentile Comparison';
    descriptionText = 'The higher the percentile, the higher the annual revenue loss';
  } else if ( props.category == 'Inconsistency' ) {
    categoryText = 'Inconsistent Pricing Results';
    profitLossCopy = 'Margin Loss From Inconsistent Pricing';
    percentileComparisonCopy = 'Inconsistent Pricing Percentile Comparison';
  } else if ( props.category == 'Misalignment' ) {
    categoryText = 'Misaligned Market Pricing Results';
    profitLossCopy = 'Margin Loss From Misaligned Market Pricing';
    percentileComparisonCopy = 'Misaligned Market Pricing Percentile Comparison';
  } else if ( props.category == 'Inefficiency' ) {
    categoryText = 'Inefficient Pricing Practices Results';
    profitLossCopy = 'Margin Loss From Inefficient Pricing Practices';
    percentileComparisonCopy = 'Inefficient Pricing Practices Percentile Comparison';
  }


  return(
    <div className={'card'} style={{marginTop: '24px', padding: '16px 16px 64px', position: 'relative'}}>

      <div style={{position: 'relative'}}>
        <Typography variant="h6" style={{marginBottom: '24px'}}>
          {categoryText}
        </Typography>

        <div className={highLowClass}>
          {selectHighLow}
        </div>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={2}>
          <img src="/publicImages/dollar-icon.svg" alt="dollar icon" />
        </Grid>

        <Grid item xs={10}>
          <p style={{marginTop: '0', color: 'rgba(0, 0, 0, 0.54)'}}>
            {profitLossCopy}
          </p>
          <Typography variant="h3">
            <FormattedNumber
              number={profitLoss}
              currency={props.currency}
            />
          </Typography>

          <Divider style={{margin: '24px 0 12px'}} />
        </Grid>
      </Grid>


      <Grid container spacing={3}>
        <Grid item xs={2}>
          <img src="/publicImages/percent-icon.svg" alt="percent icon" />
        </Grid>

        <Grid item xs={10}>
          <p style={{marginTop: '0', maxWidth: '80%', color: 'rgba(0, 0, 0, 0.54)'}}>
            {percentileComparisonCopy}
          </p>

          <GraphSelect
            churnProfitLoss = {props.churnProfitLoss}
            crossSellProfitLoss = {props.crossSellProfitLoss}
            inconsistencyProfitLoss = {props.inconsistencyProfitLoss}
            misalignmentProfitLoss = {props.misalignmentProfitLoss}
            inefficiencyProfitLoss = {props.inefficiencyProfitLoss}

            churnScore  = {props.churnScore}
            crossSellScore = {props.crossSellScore}
            inconsistencyScore = {props.inconsistencyScore}
            misalignmentScore = {props.misalignmentScore}
            inefficiencyScore = {props.inefficiencyScore}
            industry = {props.industry}
            category = {props.category}
          />
        </Grid>
      </Grid>

      <p className="graph-description-text">
        {descriptionText}
      </p>
    </div>
  );
}

export default PercentileComparison;
