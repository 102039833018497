import React from 'react';
import {
  Typography,
  Button
} from '@material-ui/core';

import Logo from '../../images/logo-white.js';
import waveImage from '../../images/results-sidebar-wave-image.svg';



function ResultsSidebar(props) {

  let downloadLink = "http://lp.zilliant.com/rs/004-KXL-870/images/zilliant-benchmark-global-b2b-benchmark-report-september-2020.pdf";

  if ( props.industry == 'Manufacturing' ) {
    downloadLink = "http://lp.zilliant.com/rs/004-KXL-870/images/zilliant-manufacturing-global-b2b-benchmark-report-september-2020.pdf";
  } else if ( props.industry == 'Distribution' ) {
    downloadLink = "http://lp.zilliant.com/rs/004-KXL-870/images/zilliant-distribution-global-b2b-benchmark-report-september-2020.pdf";
  }
  
  return(
    <div id="results-sidebar">

      <Logo/>

      <div id="results-sidebar-copy-wrapper">
        <p className={'color-white pre-heading'}>INTERACTIVE</p>

        <Typography variant="h1" className={'color-white'}>
          Customized Benchmark Report
        </Typography>

        <p>
          Thank you for completing our interactive experience! Based on your responses, this is how your company compares to the Zilliant 2020 Global Benchmark Report, an analysis of more than 1 billion B2B transactions. We've curated some content specific to your company's personalized report to help you reimagine pricing and sales. 
        </p>
        <p style={{marginTop: '24px', marginBottom: '16px'}}>
          Please share your custom report below and reach out to discuss the findings, including what percentage of the total revenue and margin loss is likely capturable for your business.
        </p>

        <Button
          className={'white-button'}
          fullWidth
          component="a"
          variant="contained"
          style={{textAlign: 'center'}}
          onClick={()=>{props.generateShareLink()}}
        >Share this report</Button>

        <p style={{textAlign: 'center'}}>or</p>

        <Button
          component="a"
          fullWidth
          variant="outlined"
          href={downloadLink}
          target="_blank"
          style={{backgroundColor: '#EE5C1C', textAlign: 'center'}}
        >Download the Global Benchmark Report</Button>
      </div>


      <img id="results-sidebar-wave-image" src={waveImage} alt="wave accent image" />
      
    </div>
  );
}
export default ResultsSidebar;
