import React from 'react';
import {
  Button
} from '@material-ui/core';
import Question from './Question/';


class QuestionaireContent extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    if(this.props.categorySelected=="Churn"){
      if(this.props.getQuestion==1){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                updateCalculatedChurnScore = {this.calculateChurnScore}
                question="My sales team struggles to spot when customers begin to drop purchase volume on products or when customers defect."
                answer=''
                radioSelect = {this.props.churnQuestionOneValue}
                setQuestion = {this.props.setQuestionOne}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==2){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                updateCalculatedChurnScore = {this.calculateChurnScore}
                question="Each sales rep in my company manages too many accounts to keep on top of each one effectively."
                answer=''
                radioSelect = {this.props.churnQuestionTwoValue}
                setQuestion = {this.props.setQuestionTwo}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==3){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
            <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionThreeContent} className="active-question-button"></Button>
            </div>
              <Question
                updateCalculatedChurnScore = {this.calculateChurnScore}
                question="My company sells from a very broad product catalog.  "
                answer=''
                radioSelect = {this.props.churnQuestionThreeValue}
                setQuestion = {this.props.setQuestionThree}
              />
          </div>
        );
      }
    }
    else if(this.props.categorySelected=="Cross-Sell"){
      if(this.props.getQuestion==1){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question=" A large percentage of my sales team behaves like order takers as opposed to order makers."
                answer=''
                radioSelect = {this.props.crossSellQuestionOneValue}
                setQuestion = {this.props.setQuestionOne}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==2){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="My sales team is only comfortable selling a handful of product categories."
                answer=''
                radioSelect = {this.props.crossSellQuestionTwoValue}
                setQuestion = {this.props.setQuestionTwo}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==3){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
            <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionThreeContent} className="active-question-button"></Button>
            </div>
              <Question
                question="A large percentage of customers are considered house accounts and do not have a dedicated sales rep."
                answer=''
                radioSelect = {this.props.crossSellQuestionThreeValue}
                setQuestion = {this.props.setQuestionThree}
              />
          </div>
        );
      }
    }
    else if(this.props.categorySelected=="Inconsistency"){
      if(this.props.getQuestion==1){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="Sales reps are free to discount to win the deal and/or create special price exceptions for customers."
                answer=''
                radioSelect = {this.props.inconsistencyQuestionOneValue}
                setQuestion = {this.props.setQuestionOne}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==2){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="My company does not have defined rules for acceptable pricing or lacks an enforceable approval process."
                answer=''
                radioSelect = {this.props.inconsistencyQuestionTwoValue}
                setQuestion = {this.props.setQuestionTwo}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==3){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
            <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionThreeContent} className="active-question-button"></Button>
            </div>
              <Question
                question="My company struggles to provide relevant eCommerce pricing."
                answer=''
                radioSelect = {this.props.inconsistencyQuestionThreeValue}
                setQuestion = {this.props.setQuestionThree}
              />
          </div>
        );
      }
    }

    else if(this.props.categorySelected=="Misalignment"){
      if(this.props.getQuestion==1){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="My company does not rely on robust analytical models that incorporate transaction data and/or other relevant market data to calculate price points."
                answer=''
                radioSelect = {this.props.misalignmentQuestionOneValue}
                setQuestion = {this.props.setQuestionOne}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==2){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="I often observe that small customers get large discounts, or that premium products don’t capture their full value in-market."
                answer=''
                radioSelect = {this.props.misalignmentQuestionTwoValue}
                setQuestion = {this.props.setQuestionTwo}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==3){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
            <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionThreeContent} className="active-question-button"></Button>
            </div>
              <Question
                question="When costs increase, attempts to increase prices are typically unsuccessful and fail to recapture the desired margin."
                answer=''
                radioSelect = {this.props.misalignmentQuestionThreeValue}
                setQuestion = {this.props.setQuestionThree}
              />
          </div>
        );
      }
    }
    else if(this.props.categorySelected=="Inefficiency"){
      if(this.props.getQuestion==1){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="It takes my company too long to update prices when costs change."
                answer=''
                radioSelect = {this.props.inefficiencyQuestionOneValue}
                setQuestion = {this.props.setQuestionOne}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==2){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
              <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
              <Button disabled={true} onClick={this.props.setQuestionTwoContent} className="active-question-button"></Button>
              <Button disabled={true} onClick={this.props.setQuestionThreeContent}></Button>
            </div>
              <Question
                question="My pricing team relies heavily on manual processes & calculations using Excel or other spreadsheet tools."
                answer=''
                radioSelect = {this.props.inefficiencyQuestionTwoValue}
                setQuestion = {this.props.setQuestionTwo}
              />
          </div>
        );
      }
      else if(this.props.getQuestion==3){
        return(
          <div className={'full-width question'}>
            <div className="question-pagination">
            <Button disabled={true} onClick={this.props.setQuestionOneContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionTwoContent}></Button>
            <Button disabled={true} onClick={this.props.setQuestionThreeContent} className="active-question-button"></Button>
            </div>
              <Question
                question="My quote response time is far longer than my customers expect."
                answer=''
                radioSelect = {this.props.inefficiencyQuestionThreeValue}
                setQuestion = {this.props.setQuestionThree}
              />
          </div>
        );
      }
    }

  }
}

export default QuestionaireContent;
