import React from 'react';
import {
  Typography
} from '@material-ui/core';
import Logo from '../../images/logo-white.js';
import homeVideo from '../../videos/home.mp4';

class HomeSidebar extends React.Component {
  constructor(props) {
    super();
  }


  render() {
    
    return(
      <>
        <div id="slide-in-overlay"></div>
        <div id="home-sidebar">

          <video id="sidebar-video" className={'background-video'} autoPlay="autoplay" muted="muted" playsInline="playsinline" loop>
            <source type="video/mp4" src={homeVideo}></source>
          </video>

          <Logo/>

          <div className="sidebar-copy-wrapper">
            <p className={'color-white pre-heading'}>
              INTERACTIVE
            </p>

            <Typography id="sidebar-title" variant="h1" className={'color-white'}>
              2020 Global B2B Benchmark Report
            </Typography>

            <div id="sidebar-body">
              <p>
                Most company leaders have a gnawing instinct that pricing and sales could be improved, and thus, lead to financial gains. Knowing precisely which processes, how to measure the loss, and most importantly, how to recapture this value is impossible with manual means.
              </p>
              <p>
                Now, you can compare your company’s performance against the Zilliant 2020 Global B2B Benchmark Report with our interactive experience
              </p>
              <p>
                At the end of the questionnaire, you will be able to download a personalized comparison report for your business. We just need a few pieces of information to select the right comparison group and calculate your results. Please select your industry to get started.
              </p>
            </div>
          </div>

        </div>

      </>
    );
  }
}

export default HomeSidebar;
