import React from 'react';
import DashboardSidebar from './Sidebar/DashboardSidebar.js';
import HomeSidebar from './Sidebar/HomeSidebar.js';
import ResultsSidebar from './Sidebar/ResultsSidebar.js';

function Sidebar(props) {

  switch(props.page) {
    case 'home':
      return(
        <HomeSidebar/>
      );
      break;
    case 'dashboard':
      return(
        <DashboardSidebar/>
      );
      break;
    case 'results':
      return(
        <ResultsSidebar
          generateShareLink={props.generateShareLink}
          companyName={props.companyName}
          industry={props.industry}
        />
      );
      break;
    default:
      return(
        <DashboardSidebar/>
      );
  }

}

export default Sidebar;
