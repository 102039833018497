import React from 'react';

import {
  Typography,
  Grid,
  Divider,
  Button
} from '@material-ui/core';

import QuestionaireContent from './questionaireContent';
import MedalIcon from '../../../../images/medal.js';
import Congratulations from './congratulations.js'

import ChurnIcon from '../../../../images/churn.js';
import CrossSellIcon from '../../../../images/cross-sell.js';
import InconsistencyIcon from '../../../../images/inconsistency.js';
import MisalignmentIcon from '../../../../images/misalignment.js';
import InefficiencyIcon from '../../../../images/inefficiency.js';

class Questionnaire extends React.Component {
  constructor(props) {
    super();
    this.state = {
      activeCategory: 'Churn',
      churnPercent:0,
      crossSellPercent:0,
      inconsistencyPercent:0,
      misalignmentPercent:0,
      inefficiencyPercent:0,

      currentTabButtonActive:false,

      disableCompleteTabOne:true,
      disableCompleteTabTwo:true,
      disableCompleteTabThree:true,
      disableCompleteTabFour:true,
      disableCompleteTabFive:true,

      churnQuestionOneValue:"0",
      churnQuestionTwoValue:"0",
      churnQuestionThreeValue:"0",
      crossSellQuestionOneValue:"0",
      crossSellQuestionTwoValue:"0",
      crossSellQuestionThreeValue:"0",
      inconsistencyQuestionOneValue:"0",
      inconsistencyQuestionTwoValue:"0",
      inconsistencyQuestionThreeValue:"0",
      misalignmentQuestionOneValue:"0",
      misalignmentQuestionTwoValue:"0",
      misalignmentQuestionThreeValue:"0",
      inefficiencyQuestionOneValue:"0",
      inefficiencyQuestionTwoValue:"0",
      inefficiencyQuestionThreeValue:"0"

    }
    this.setQuestionOne = this.setQuestionOne.bind(this)
    this.setQuestionTwo = this.setQuestionTwo.bind(this)
    this.setQuestionThree = this.setQuestionThree.bind(this)

    this.getChurnPercent = this.getChurnPercent.bind(this)
  }


    calculateChurnScore = () =>{
      let totalChurnScore = Number(this.state.churnQuestionOneValue)+Number(this.state.churnQuestionTwoValue)+Number(this.state.churnQuestionThreeValue)
      let percentFactor = 1
      if(this.props.industry=="Distribution"){
        if(totalChurnScore==3){
          percentFactor = 4.33
        }
        else if(totalChurnScore==4){
          percentFactor = 5.12
        }
        else if(totalChurnScore==5){
          percentFactor = 5.91

        }
        else if(totalChurnScore==6){
          percentFactor = 6.71

        }
        else if(totalChurnScore==7){
          percentFactor = 7.50

        }
        else if(totalChurnScore==8){
          percentFactor = 8.29

        }
        else if(totalChurnScore==9){
          percentFactor = 9.08

        }
        else if(totalChurnScore==10){
          percentFactor = 10.19

        }
        else if(totalChurnScore==11){
          percentFactor = 11.31

        }
        else if(totalChurnScore==12){
          percentFactor = 12.42

        }
        else if(totalChurnScore==13){
          percentFactor = 13.54

        }
        else if(totalChurnScore==14){
          percentFactor = 14.65

        }
        else if(totalChurnScore==15){
          percentFactor = 15.77
        }
      }
      else if(this.props.industry=="Manufacturing"){
        if(totalChurnScore==3){
          percentFactor = 4.28
        }
        else if(totalChurnScore==4){
          percentFactor = 5.04
        }
        else if(totalChurnScore==5){
          percentFactor = 5.81
        }
        else if(totalChurnScore==6){
          percentFactor = 6.57
        }
        else if(totalChurnScore==7){
          percentFactor = 7.33
        }
        else if(totalChurnScore==8){
          percentFactor = 8.10
        }
        else if(totalChurnScore==9){
          percentFactor = 8.86
        }
        else if(totalChurnScore==10){
          percentFactor = 10.07
        }
        else if(totalChurnScore==11){
          percentFactor = 11.28
        }
        else if(totalChurnScore==12){
          percentFactor = 12.49
        }
        else if(totalChurnScore==13){
          percentFactor = 13.70
        }
        else if(totalChurnScore==14){
          percentFactor = 14.91
        }
        else if(totalChurnScore==15){
          percentFactor = 16.12
        }


      }
      else if(this.props.industry=="Other"){
        if(totalChurnScore==3){
          percentFactor = 4.45
        }
        else if(totalChurnScore==4){
          percentFactor = 5.28
        }
        else if(totalChurnScore==5){
          percentFactor = 6.10
        }
        else if(totalChurnScore==6){
          percentFactor = 6.92
        }
        else if(totalChurnScore==7){
          percentFactor = 7.74
        }
        else if(totalChurnScore==8){
          percentFactor = 8.57
        }
        else if(totalChurnScore==9){
          percentFactor = 9.39
        }
        else if(totalChurnScore==10){
          percentFactor = 10.50
        }
        else if(totalChurnScore==11){
          percentFactor = 11.62
        }
        else if(totalChurnScore==12){
          percentFactor = 12.73
        }
        else if(totalChurnScore==13){
          percentFactor = 13.85
        }
        else if(totalChurnScore==14){
          percentFactor = 14.96
        }
        else if(totalChurnScore==15){
          percentFactor = 16.07
        }
      }


      this.props.updateChurnProfitLoss(percentFactor)

    }

    calculateCrossSellScore = () =>{
      let totalCrossSellScore = Number(this.state.crossSellQuestionOneValue)+Number(this.state.crossSellQuestionTwoValue)+Number(this.state.crossSellQuestionThreeValue)
      let percentFactor = 1

      if(this.props.industry=="Distribution"){

        if(totalCrossSellScore==3){
          percentFactor = 2.92
        }
        else if(totalCrossSellScore==4){
          percentFactor = 3.61
        }
        else if(totalCrossSellScore==5){
          percentFactor = 4.31
        }
        else if(totalCrossSellScore==6){
          percentFactor = 5.00
        }
        else if(totalCrossSellScore==7){
          percentFactor = 5.70
        }
        else if(totalCrossSellScore==8){
          percentFactor = 6.39
        }
        else if(totalCrossSellScore==9){
          percentFactor = 7.09
        }
        else if(totalCrossSellScore==10){
          percentFactor = 8.14
        }
        else if(totalCrossSellScore==11){
          percentFactor = 9.19
        }
        else if(totalCrossSellScore==12){
          percentFactor = 10.24
        }
        else if(totalCrossSellScore==13){
          percentFactor = 11.30
        }
        else if(totalCrossSellScore==14){
          percentFactor = 12.35
        }
        else if(totalCrossSellScore==15){
          percentFactor = 13.40
        }
      }
      else if(this.props.industry=="Manufacturing"){
        if(totalCrossSellScore==3){
          percentFactor = 4.69
        }
        else if(totalCrossSellScore==4){
          percentFactor = 5.70
        }
        else if(totalCrossSellScore==5){
          percentFactor = 6.72
        }
        else if(totalCrossSellScore==6){
          percentFactor = 7.73
        }
        else if(totalCrossSellScore==7){
          percentFactor = 8.75
        }
        else if(totalCrossSellScore==8){
          percentFactor = 9.76
        }
        else if(totalCrossSellScore==9){
          percentFactor = 10.78
        }
        else if(totalCrossSellScore==10){
          percentFactor = 12.44
        }
        else if(totalCrossSellScore==11){
          percentFactor = 14.10
        }
        else if(totalCrossSellScore==12){
          percentFactor = 15.77
        }
        else if(totalCrossSellScore==13){
          percentFactor = 17.43
        }
        else if(totalCrossSellScore==14){
          percentFactor = 19.09
        }
        else if(totalCrossSellScore==15){
          percentFactor = 20.76
        }

      }
      else if(this.props.industry=="Other"){
        if(totalCrossSellScore==3){
          percentFactor = 3.19
        }
        else if(totalCrossSellScore==4){
          percentFactor = 3.95
        }
        else if(totalCrossSellScore==5){
          percentFactor = 4.70
        }
        else if(totalCrossSellScore==6){
          percentFactor = 5.46
        }
        else if(totalCrossSellScore==7){
          percentFactor = 6.22
        }
        else if(totalCrossSellScore==8){
          percentFactor = 6.98
        }
        else if(totalCrossSellScore==9){
          percentFactor = 7.74
        }
        else if(totalCrossSellScore==10){
          percentFactor = 9.06
        }
        else if(totalCrossSellScore==11){
          percentFactor = 10.38
        }
        else if(totalCrossSellScore==12){
          percentFactor = 11.71
        }
        else if(totalCrossSellScore==13){
          percentFactor = 13.03
        }
        else if(totalCrossSellScore==14){
          percentFactor = 14.35
        }
        else if(totalCrossSellScore==15){
          percentFactor = 15.67
        }

      }
      this.props.updateCrossSellProfitLoss(percentFactor)


    }

    calculateInconsistencyScore = () =>{
      let totalInconsistencyScore = Number(this.state.inconsistencyQuestionOneValue)+Number(this.state.inconsistencyQuestionTwoValue)+Number(this.state.inconsistencyQuestionThreeValue)
      let percentFactor = 1

      if(this.props.industry=="Distribution"){

        if(totalInconsistencyScore==3){
          percentFactor = 1.55
        }
        else if(totalInconsistencyScore==4){
          percentFactor = 1.91
        }
        else if(totalInconsistencyScore==5){
          percentFactor = 2.27
        }
        else if(totalInconsistencyScore==6){
          percentFactor = 2.63
        }
        else if(totalInconsistencyScore==7){
          percentFactor = 2.99
        }
        else if(totalInconsistencyScore==8){
          percentFactor = 3.35
        }
        else if(totalInconsistencyScore==9){
          percentFactor = 3.71
        }
        else if(totalInconsistencyScore==10){
          percentFactor = 4.35
        }
        else if(totalInconsistencyScore==11){
          percentFactor = 5.00
        }
        else if(totalInconsistencyScore==12){
          percentFactor = 5.64
        }
        else if(totalInconsistencyScore==13){
          percentFactor = 6.28
        }
        else if(totalInconsistencyScore==14){
          percentFactor = 6.92
        }
        else if(totalInconsistencyScore==15){
          percentFactor = 7.57
        }
      }
      else if(this.props.industry=="Manufacturing"){
        if(totalInconsistencyScore==3){
          percentFactor = 1.05
        }
        else if(totalInconsistencyScore==4){
          percentFactor = 1.55
        }
        else if(totalInconsistencyScore==5){
          percentFactor = 2.05
        }
        else if(totalInconsistencyScore==6){
          percentFactor = 2.55
        }
        else if(totalInconsistencyScore==7){
          percentFactor = 3.05
        }
        else if(totalInconsistencyScore==8){
          percentFactor = 3.55
        }
        else if(totalInconsistencyScore==9){
          percentFactor = 4.06
        }
        else if(totalInconsistencyScore==10){
          percentFactor = 4.85
        }
        else if(totalInconsistencyScore==11){
          percentFactor = 5.64
        }
        else if(totalInconsistencyScore==12){
          percentFactor = 6.43
        }
        else if(totalInconsistencyScore==13){
          percentFactor = 7.22
        }
        else if(totalInconsistencyScore==14){
          percentFactor = 8.01
        }
        else if(totalInconsistencyScore==15){
          percentFactor = 8.80
        }

      }
      else if(this.props.industry=="Other"){
        if(totalInconsistencyScore==3){
          percentFactor = 1.37
        }
        else if(totalInconsistencyScore==4){
          percentFactor = 1.80
        }
        else if(totalInconsistencyScore==5){
          percentFactor = 2.23
        }
        else if(totalInconsistencyScore==6){
          percentFactor = 2.66
        }
        else if(totalInconsistencyScore==7){
          percentFactor = 3.10
        }
        else if(totalInconsistencyScore==8){
          percentFactor = 3.53
        }
        else if(totalInconsistencyScore==9){
          percentFactor = 3.96
        }
        else if(totalInconsistencyScore==10){
          percentFactor = 4.65
        }
        else if(totalInconsistencyScore==11){
          percentFactor = 5.35
        }
        else if(totalInconsistencyScore==12){
          percentFactor = 6.04
        }
        else if(totalInconsistencyScore==13){
          percentFactor = 6.74
        }
        else if(totalInconsistencyScore==14){
          percentFactor = 7.43
        }
        else if(totalInconsistencyScore==15){
          percentFactor = 8.12
        }

      }
      this.props.updateInconsistencyProfitLoss(percentFactor)


    }

    calculateMisalignmentScore = () =>{
      let totalMisalignmentScore = Number(this.state.misalignmentQuestionOneValue)+Number(this.state.misalignmentQuestionTwoValue)+Number(this.state.misalignmentQuestionThreeValue)
      let percentFactor = 1

        if(this.props.industry=="Distribution"){

        if(totalMisalignmentScore==3){
          percentFactor = 0.58
        }
        else if(totalMisalignmentScore==4){
          percentFactor = 0.88
        }
        else if(totalMisalignmentScore==5){
          percentFactor = 1.17
        }
        else if(totalMisalignmentScore==6){
          percentFactor = 1.47
        }
        else if(totalMisalignmentScore==7){
          percentFactor = 1.76
        }
        else if(totalMisalignmentScore==8){
          percentFactor = 2.06
        }
        else if(totalMisalignmentScore==9){
          percentFactor = 2.35
        }
        else if(totalMisalignmentScore==10){
          percentFactor = 2.91
        }
        else if(totalMisalignmentScore==11){
          percentFactor = 3.48
        }
        else if(totalMisalignmentScore==12){
          percentFactor = 4.04
        }
        else if(totalMisalignmentScore==13){
          percentFactor = 4.60
        }
        else if(totalMisalignmentScore==14){
          percentFactor = 5.17
        }
        else if(totalMisalignmentScore==15){
          percentFactor = 5.73
        }
      }
      else if(this.props.industry=="Manufacturing"){
        if(totalMisalignmentScore==3){
          percentFactor = 0.51
        }
        else if(totalMisalignmentScore==4){
          percentFactor = 0.78
        }
        else if(totalMisalignmentScore==5){
          percentFactor = 1.05
        }
        else if(totalMisalignmentScore==6){
          percentFactor = 1.32
        }
        else if(totalMisalignmentScore==7){
          percentFactor = 1.58
        }
        else if(totalMisalignmentScore==8){
          percentFactor = 1.85
        }
        else if(totalMisalignmentScore==9){
          percentFactor = 2.12
        }
        else if(totalMisalignmentScore==10){
          percentFactor = 3.01
        }
        else if(totalMisalignmentScore==11){
          percentFactor = 3.90
        }
        else if(totalMisalignmentScore==12){
          percentFactor = 4.80
        }
        else if(totalMisalignmentScore==13){
          percentFactor = 5.69
        }
        else if(totalMisalignmentScore==14){
          percentFactor = 6.58
        }
        else if(totalMisalignmentScore==15){
          percentFactor = 7.47
        }

      }
      else if(this.props.industry=="Other"){
        if(totalMisalignmentScore==3){
          percentFactor = 0.52
        }
        else if(totalMisalignmentScore==4){
          percentFactor = 0.78
        }
        else if(totalMisalignmentScore==5){
          percentFactor = 1.05
        }
        else if(totalMisalignmentScore==6){
          percentFactor = 1.31
        }
        else if(totalMisalignmentScore==7){
          percentFactor = 1.57
        }
        else if(totalMisalignmentScore==8){
          percentFactor = 1.83
        }
        else if(totalMisalignmentScore==9){
          percentFactor = 2.09
        }
        else if(totalMisalignmentScore==10){
          percentFactor = 2.84
        }
        else if(totalMisalignmentScore==11){
          percentFactor = 3.59
        }
        else if(totalMisalignmentScore==12){
          percentFactor = 4.34
        }
        else if(totalMisalignmentScore==13){
          percentFactor = 5.08
        }
        else if(totalMisalignmentScore==14){
          percentFactor = 5.83
        }
        else if(totalMisalignmentScore==15){
          percentFactor = 6.58
        }

      }

      this.props.updateMisalignmentProfitLoss(percentFactor)

    }

    calculateInefficiencyScore = () =>{
      let totalInefficiencyScore = Number(this.state.inefficiencyQuestionOneValue)+Number(this.state.inefficiencyQuestionTwoValue)+Number(this.state.inefficiencyQuestionThreeValue)
      let percentFactor = 1
      if(this.props.industry=="Distribution"){

        if(totalInefficiencyScore==3){
          percentFactor = 0.76
        }
        else if(totalInefficiencyScore==4){
          percentFactor = 0.90
        }
        else if(totalInefficiencyScore==5){
          percentFactor = 1.03
        }
        else if(totalInefficiencyScore==6){
          percentFactor = 1.17
        }
        else if(totalInefficiencyScore==7){
          percentFactor = 1.31
        }
        else if(totalInefficiencyScore==8){
          percentFactor = 1.44
        }
        else if(totalInefficiencyScore==9){
          percentFactor = 1.58
        }
        else if(totalInefficiencyScore==10){
          percentFactor = 1.72
        }
        else if(totalInefficiencyScore==11){
          percentFactor = 1.85
        }
        else if(totalInefficiencyScore==12){
          percentFactor = 1.99
        }
        else if(totalInefficiencyScore==13){
          percentFactor = 2.13
        }
        else if(totalInefficiencyScore==14){
          percentFactor = 2.26
        }
        else if(totalInefficiencyScore==15){
          percentFactor = 2.40
        }
      }
      else if(this.props.industry=="Manufacturing"){
        if(totalInefficiencyScore==3){
          percentFactor = 0.76
        }
        else if(totalInefficiencyScore==4){
          percentFactor = 0.90
        }
        else if(totalInefficiencyScore==5){
          percentFactor = 1.03
        }
        else if(totalInefficiencyScore==6){
          percentFactor = 1.17
        }
        else if(totalInefficiencyScore==7){
          percentFactor = 1.31
        }
        else if(totalInefficiencyScore==8){
          percentFactor = 1.44
        }
        else if(totalInefficiencyScore==9){
          percentFactor = 1.58
        }
        else if(totalInefficiencyScore==10){
          percentFactor = 1.72
        }
        else if(totalInefficiencyScore==11){
          percentFactor = 1.85
        }
        else if(totalInefficiencyScore==12){
          percentFactor = 1.99
        }
        else if(totalInefficiencyScore==13){
          percentFactor = 2.13
        }
        else if(totalInefficiencyScore==14){
          percentFactor = 2.26
        }
        else if(totalInefficiencyScore==15){
          percentFactor = 2.40
        }

      }
      else if(this.props.industry=="Other"){
        if(totalInefficiencyScore==3){
          percentFactor = 0.76
        }
        else if(totalInefficiencyScore==4){
          percentFactor = 0.90
        }
        else if(totalInefficiencyScore==5){
          percentFactor = 1.03
        }
        else if(totalInefficiencyScore==6){
          percentFactor = 1.17
        }
        else if(totalInefficiencyScore==7){
          percentFactor = 1.31
        }
        else if(totalInefficiencyScore==8){
          percentFactor = 1.44
        }
        else if(totalInefficiencyScore==9){
          percentFactor = 1.58
        }
        else if(totalInefficiencyScore==10){
          percentFactor = 1.72
        }
        else if(totalInefficiencyScore==11){
          percentFactor = 1.85
        }
        else if(totalInefficiencyScore==12){
          percentFactor = 1.99
        }
        else if(totalInefficiencyScore==13){
          percentFactor = 2.13
        }
        else if(totalInefficiencyScore==14){
          percentFactor = 2.26
        }
        else if(totalInefficiencyScore==15){
          percentFactor = 2.40
        }

      }
      this.props.updateInefficiencyProfitLoss(percentFactor)
    }


  setQuestionOne = (data) =>{
    if(this.props.categorySelected=='Churn'){
      this.setState({churnQuestionOneValue: data.target.value}, () => {
        if(this.state.churnQuestionOneValue!=0&&this.state.churnQuestionTwoValue!=0&&this.state.churnQuestionThreeValue!=0){
          this.calculateChurnScore()
        }
      });
    }
    else if(this.props.categorySelected=='Cross-Sell'){
      this.setState({crossSellQuestionOneValue:data.target.value}, () => {
        if(this.state.crossSellQuestionOneValue!=0&&this.state.crossSellQuestionTwoValue!=0&&this.state.crossSellQuestionThreeValue!=0){
          this.calculateCrossSellScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inconsistency'){
      this.setState({inconsistencyQuestionOneValue:data.target.value}, () => {
        if(this.state.inconsistencyQuestionOneValue!=0&&this.state.inconsistencyQuestionTwoValue!=0&&this.state.inconsistencyQuestionThreeValue!=0){
          this.calculateInconsistencyScore()
        }
      });
    }
    else if(this.props.categorySelected=='Misalignment'){
      this.setState({misalignmentQuestionOneValue:data.target.value}, () => {
        if(this.state.misalignmentQuestionOneValue!=0&&this.state.misalignmentQuestionTwoValue!=0&&this.state.misalignmentQuestionThreeValue!=0){
          this.calculateMisalignmentScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inefficiency'){
      this.setState({inefficiencyQuestionOneValue:data.target.value}, () => {
        if(this.state.inefficiencyQuestionOneValue!=0&&this.state.inefficiencyQuestionTwoValue!=0&&this.state.inefficiencyQuestionThreeValue!=0){
          this.calculateInefficiencyScore()
        }
      });
    }
  }

  setQuestionTwo = (data) =>{
    if(this.props.categorySelected=='Churn'){
      this.setState({churnQuestionTwoValue: data.target.value}, () => {
        if(this.state.churnQuestionOneValue!=0&&this.state.churnQuestionTwoValue!=0&&this.state.churnQuestionThreeValue!=0){
          this.calculateChurnScore()
        }
      });
    }
    else if(this.props.categorySelected=='Cross-Sell'){
      this.setState({crossSellQuestionTwoValue:data.target.value}, () => {
        if(this.state.crossSellQuestionOneValue!=0&&this.state.crossSellQuestionTwoValue!=0&&this.state.crossSellQuestionThreeValue!=0){
          this.calculateCrossSellScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inconsistency'){
      this.setState({inconsistencyQuestionTwoValue:data.target.value}, () => {
        if(this.state.inconsistencyQuestionOneValue!=0&&this.state.inconsistencyQuestionTwoValue!=0&&this.state.inconsistencyQuestionThreeValue!=0){
          this.calculateInconsistencyScore()
        }
      });
    }
    else if(this.props.categorySelected=='Misalignment'){
      this.setState({misalignmentQuestionTwoValue:data.target.value}, () => {
        if(this.state.misalignmentQuestionOneValue!=0&&this.state.misalignmentQuestionTwoValue!=0&&this.state.misalignmentQuestionThreeValue!=0){
          this.calculateMisalignmentScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inefficiency'){
      this.setState({inefficiencyQuestionTwoValue:data.target.value}, () => {
        if(this.state.inefficiencyQuestionOneValue!=0&&this.state.inefficiencyQuestionTwoValue!=0&&this.state.inefficiencyQuestionThreeValue!=0){
          this.calculateInefficiencyScore()
        }
      });
    }
  }
  setQuestionThree = (data) =>{
    if(this.props.categorySelected=='Churn'){
      this.setState({churnQuestionThreeValue: data.target.value}, () => {
        if(this.state.churnQuestionOneValue!=0&&this.state.churnQuestionTwoValue!=0&&this.state.churnQuestionThreeValue!=0){
          this.calculateChurnScore()
        }
      });
    }
    else if(this.props.categorySelected=='Cross-Sell'){
      this.setState({crossSellQuestionThreeValue:data.target.value}, () => {
        if(this.state.crossSellQuestionOneValue!=0&&this.state.crossSellQuestionTwoValue!=0&&this.state.crossSellQuestionThreeValue!=0){
          this.calculateCrossSellScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inconsistency'){
      this.setState({inconsistencyQuestionThreeValue:data.target.value}, () => {
        if(this.state.inconsistencyQuestionOneValue!=0&&this.state.inconsistencyQuestionTwoValue!=0&&this.state.inconsistencyQuestionThreeValue!=0){
          this.calculateInconsistencyScore()
        }
      });
    }
    else if(this.props.categorySelected=='Misalignment'){
      this.setState({misalignmentQuestionThreeValue:data.target.value}, () => {
        if(this.state.misalignmentQuestionOneValue!=0&&this.state.misalignmentQuestionTwoValue!=0&&this.state.misalignmentQuestionThreeValue!=0){
          this.calculateMisalignmentScore()
        }
      });
    }
    else if(this.props.categorySelected=='Inefficiency'){
      this.setState({inefficiencyQuestionThreeValue:data.target.value}, () => {
        if(this.state.inefficiencyQuestionOneValue!=0&&this.state.inefficiencyQuestionTwoValue!=0&&this.state.inefficiencyQuestionThreeValue!=0){
          this.calculateInefficiencyScore()
        }
      });
    }
  }


  executeTransitionToCrossSellTab = () =>{
      this.props.updateHighLow('Churn')
      setTimeout(
      function() {
        this.props.selectCrossSellTab()
        this.props.displayTotalRevenueAndMarginLoss()
      }
      .bind(this),
      2000
      );

  }
  executeTransitionToInconsistencyTab = () =>{

      this.props.updateHighLow('Cross-Sell')
      setTimeout(
      function() {
        this.props.selectInconsistencyTab()
        this.props.displayTotalRevenueAndMarginLoss()
      }
      .bind(this),
      2000
      );

  }
  executeTransitionToMisalignmentTab = () =>{

      this.props.updateHighLow('Inconsistency')
      setTimeout(
      function() {
        this.props.selectMisalignmentTab()
        this.props.displayTotalRevenueAndMarginLoss()
      }
      .bind(this),
      2000
      );

  }
  executeTransitionToInefficiencyTab = () =>{
      this.props.updateHighLow('Misalignment')
      setTimeout(
      function() {
        this.props.selectInefficiencyTab()
        this.props.displayTotalRevenueAndMarginLoss()
      }
      .bind(this),
      2000
      );
  }
executeTransitionToCongratulationsScreen =()=>{
    this.props.updateHighLow('Inefficiency')
    setTimeout(
    function() {
      this.props.updateCongratulationsReplacementContent(true)
      this.props.displayTotalRevenueAndMarginLoss()
    }
    .bind(this),
    2000
    );
}
executeTabTransition = () =>{
  if(this.props.categorySelected == "Churn"){
    this.executeTransitionToCrossSellTab()
  }
  else if(this.props.categorySelected == "Cross-Sell"){
    this.executeTransitionToInconsistencyTab()
  }
  else if(this.props.categorySelected == "Inconsistency"){
    this.executeTransitionToMisalignmentTab()
  }
  else if(this.props.categorySelected == "Misalignment"){
    this.executeTransitionToInefficiencyTab()
  }
  else if(this.props.categorySelected == "Inefficiency"){
    this.executeTransitionToCongratulationsScreen()
  }
}

  iconForTab =(props) =>{
    if(this.props.categorySelected == "Churn")
      return <ChurnIcon/>;
    else   if(this.props.categorySelected == "Cross-Sell")
      return <CrossSellIcon/>;
    else if(this.props.categorySelected == "Inconsistency")
      return <InconsistencyIcon/>;
    else if(this.props.categorySelected == "Misalignment")
      return <MisalignmentIcon/>;
    else if(this.props.categorySelected == "Inefficiency")
      return <InefficiencyIcon/>;
  }

  getChurnPercent = (data) =>{
      this.setState({churnPercent:data},function(){
      this.props.updateDashChurnTotal(this.state.churnPercent)
    })
  }

  getChurnResults = ()=>{
    if(this.state.churnPercent>3){
      alert(this.state.churnPercent+' churn looking good')
    }
  }

  setToQuestionOne = () =>{
    this.setState({questionSelected:1})
  }
  setToQuestionTwo = () =>{
    this.setState({questionSelected:2})

  }
  setToQuestionThree = () =>{
    this.setState({questionSelected:3})
  }
  render() {

    if(this.props.tabSectionComplete==1){
      this.state.disableCompleteTabOne = false;
    }
    else if(this.props.tabSectionComplete==2){
        this.state.disableCompleteTabTwo = false;
    }
    else if(this.props.tabSectionComplete==3){
        this.state.disableCompleteTabThree = false;
    }
    else if(this.props.tabSectionComplete==4){
        this.state.disableCompleteTabFour = false;
    }
    else if(this.props.tabSectionComplete==5){
        this.state.disableCompleteTabFive = false;
    }

    let pTagDisplay = ""
    let titalH2 = ""
    let currentButtonActive = false

    if(this.props.categorySelected == "Churn"){
      currentButtonActive = this.state.disableCompleteTabOne;
      titalH2 = "Customer Churn"
      pTagDisplay = "Customer churn measures accounts that defect to competitors and accounts with declining purchase volume in one or more product categories. Answer these three, short questions to estimate revenue loss due to customer churn."
    }
    else if(this.props.categorySelected == "Cross-Sell"){
      titalH2 = "Missed Cross-Sell"
      currentButtonActive = this.state.disableCompleteTabTwo
      pTagDisplay = "Missed cross-sell accounts for lower-than-expected wallet share based on a customer's quantified spend potential. Answer these three, short questions to estimate revenue loss due to missed cross-sell."
    }
    else if (this.props.categorySelected == "Inconsistency") {
      titalH2 = "Inconsistent Pricing"
      currentButtonActive = this.state.disableCompleteTabThree
      pTagDisplay = "Prices that are inconsistent based on market, order, product or customer circumstances, including smaller customers receiving better prices than larger customers, or when too many transactions are over or underpriced. Answer these three, short questions to estimate margin loss due to inconsistent pricing."
    }
    else if (this.props.categorySelected == "Misalignment") {
      titalH2 = "Misaligned Market Pricing"
      currentButtonActive = this.state.disableCompleteTabFour
      pTagDisplay = "Failing to quickly and accurately update pricing when costs change or when, with a lack of contextual price guidance, sales reps arbitrarily round prices up or down. Answer these three, short questions to estimate margin loss due to misaligned market pricing."
    }
    else if (this.props.categorySelected == "Inefficiency") {
      titalH2 = "Inefficient Pricing Practices"
      currentButtonActive = this.state.disableCompleteTabFive
      pTagDisplay = "An over-reliance on manual processes and calculations to update prices results in a slow response to pricing triggers and a lag in quote response time, creating inefficiency. Answer these three, short questions to estimate margin loss due to inefficient pricing practices."
    }

    if(this.props.congratulationReplacementContent == true){
      return(

          <div id="questionnaire-wrapper" className="card" style={{padding: '48px', marginTop: '24px'}}>
            <div id="spinner-wrapper"></div>
            <Grid container id="questionnaire">
              <Grid item container xs={12} style={{justifyContent: 'center'}}>
                <Congratulations
                  selectPageFromRouting = {this.props.selectPageFromRouting}
                  formData={this.props.formData}
                  returnShareLink={this.props.returnShareLink}
                />
              </Grid>
            </Grid>
          </div>
      );
    }
    else{
      return(
        <div id="questionnaire-wrapper" className="card" style={{padding: '48px', marginTop: '24px'}}>
          <div id="spinner-wrapper"></div>
          <Grid container id="questionnaire" spacing={3}>
            <Grid item xs={2}>
              {this.iconForTab()}
            </Grid>
            <Grid item container xs={8}>
              <Typography variant="h2">{titalH2}</Typography>
              <p>
                {pTagDisplay}
              </p>
              <Divider className="full-width" />
              <QuestionaireContent
                setQuestionOne = {this.setQuestionOne}
                setQuestionTwo = {this.setQuestionTwo}
                setQuestionThree = {this.setQuestionThree}

                getChurnScore = {this.getChurnPercent}
                updateChurnProfitLoss = {this.props.updateChurnProfitLoss}
                updateCrossSellProfitLoss = {this.props.updateCrossSellProfitLoss}
                updateInconsistencyProfitLoss = {this.props.updateInconsistencyProfitLoss}
                updateMisalignmentProfitLoss = {this.props.updateMisalignmentProfitLoss}
                updateInefficiencyProfitLoss = {this.props.updateInefficiencyProfitLoss}
                categorySelected={this.props.categorySelected}
                getQuestion={this.props.questionSelected}
                setQuestionOneContent = {this.setToQuestionOne}
                setQuestionTwoContent = {this.setToQuestionTwo}
                setQuestionThreeContent = {this.setToQuestionThree}


                churnQuestionOneValue = {this.state.churnQuestionOneValue}
                churnQuestionTwoValue = {this.state.churnQuestionTwoValue}
                churnQuestionThreeValue = {this.state.churnQuestionThreeValue}
                crossSellQuestionOneValue = {this.state.crossSellQuestionOneValue}
                crossSellQuestionTwoValue = {this.state.crossSellQuestionTwoValue}
                crossSellQuestionThreeValue = {this.state.crossSellQuestionThreeValue}
                inconsistencyQuestionOneValue = {this.state.inconsistencyQuestionOneValue}
                inconsistencyQuestionTwoValue = {this.state.inconsistencyQuestionTwoValue}
                inconsistencyQuestionThreeValue = {this.state.inconsistencyQuestionThreeValue}
                misalignmentQuestionOneValue = {this.state.misalignmentQuestionOneValue}
                misalignmentQuestionTwoValue = {this.state.misalignmentQuestionTwoValue}
                misalignmentQuestionThreeValue = {this.state.misalignmentQuestionThreeValue}
                inefficiencyQuestionOneValue = {this.state.inefficiencyQuestionOneValue}
                inefficiencyQuestionTwoValue = {this.state.inefficiencyQuestionTwoValue}
                inefficiencyQuestionThreeValue = {this.state.inefficiencyQuestionThreeValue}
              />

              <Divider style={{width: '100%', marginBottom: '24px', marginTop: '24px'}} />

              <Button
                className="question-button"
                onClick={this.props.previousQuestion}
                disabled={this.props.questionSelected === 1}
              >Back</Button>

              <Button
                className="question-button"
                onClick={this.props.nextQuestion}
                disabled={this.props.questionSelected === 3}
              >Next</Button>

              <span style={{marginRight: '24px'}}>|</span>

              <Button
                className="question-button"
                disabled={currentButtonActive}
                onClick={()=>{this.executeTabTransition()}}
              >Complete Section</Button>

            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default Questionnaire;
