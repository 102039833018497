import React from 'react';
import Dashboard from '../components/pages/Dashboard.js';
import Home from '../components/pages/Home.js';
import Results from '../components/pages/Results.js';
import Iframe from 'react-iframe';

class Routing extends React.Component {
  constructor(props) {
    super();
    this.state = {
      pageSelected: "Home",

      homeContentState: "industrySelect",

      churnScore:0,
      crossSellScore:0,
      inconsistencyScore:0,
      misalignmentScore:0,
      inefficiencyScore:0,

      tabSectionComplete:0,

      churnProfitLoss:0,
      crossSellProfitLoss:0,
      inconsistencyProfitLoss:0,
      misalignmentProfitLoss:0,
      inefficiencyProfitLoss:0,

      autoUpdateChurnUnlocked:false,
      autoUpdateCrossSellUnlocked:false,
      autoUpdateInconsistencyUnlocked:false,
      autoUpdateMisalignmentUnlocked:false,
      autoUpdateInefficiencyUnlocked:false,

      totalAnnualRevenueLoss:0,
      totalAnnualMarginLoss:0,
      totalProfitLoss:0,
      churnHighLow:'',
      crossSellHighLow:'',
      inconsistencyHighLow:'',
      misalignmentHighLow:'',
      inefficiencyHighLow:'',
      industry:'',
      revenue: 0,
      subIndustry: '',
      companyName: '',
      currency: 'USD'
    };


    this.generateShareLink = this.generateShareLink.bind(this);

    this.updateFormData = this.updateFormData.bind(this)
    this.selectPage = this.selectPage.bind(this)

    this.displayTotalRevenueAndMarginLoss = this.displayTotalRevenueAndMarginLoss.bind(this)

    this.updateChurnProfitLoss = this.updateChurnProfitLoss.bind(this)
    this.updateCrossSellProfitLoss = this.updateCrossSellProfitLoss.bind(this)
    this.updateInconsistencyProfitLoss = this.updateInconsistencyProfitLoss.bind(this)
    this.updateMisalignmentProfitLoss = this.updateMisalignmentProfitLoss.bind(this)
    this.updateInefficiencyProfitLoss = this.updateInefficiencyProfitLoss.bind(this)

    this.updateIndustry = this.updateIndustry.bind(this)
  }

  formData = {
    industry:'',
    firstName:'',
    lastName:'',
    companyName:'',
    emailAddress:'',
    revenue:'',
    subIndustry:'',
    shareLink:''
  }


  componentDidMount() {
    let queryString = window.location.search.replace('?', '').split('&');
    Object.entries(queryString).map((keyValue, index) => {
      let keyValuePair = keyValue[1].split('=');
      let key = keyValuePair[0];
      let value = keyValuePair[1];
      if ( key == 'pageSelected' ) { this.setState({pageSelected: value}) }
      if ( key == 'companyName' ) { this.setState({companyName: decodeURI(value)}) }
      if ( key == 'industry' ) { this.setState({industry: value}) }
      if ( key == 'subIndustry' ) { this.setState({subIndustry: decodeURI(value)}) }
      if ( key == 'revenue' ) { this.setState({revenue: Number(value)}) }
      if ( key == 'currency' ) { this.setState({currency: value}) }
      if ( key == 'churnScore' ) { this.setState({churnScore: Number(value)}) }
      if ( key == 'crossSellScore' ) { this.setState({crossSellScore: Number(value)}) }
      if ( key == 'inconsistencyScore' ) { this.setState({inconsistencyScore: Number(value)}) }
      if ( key == 'misalignmentScore' ) { this.setState({misalignmentScore: Number(value)}) }
      if ( key == 'inefficiencyScore' ) { this.setState({inefficiencyScore: Number(value)}) }
      if ( key == 'churnProfitLoss' ) { this.setState({churnProfitLoss: Number(value)}) }
      if ( key == 'crossSellProfitLoss' ) { this.setState({crossSellProfitLoss: Number(value)}) }
      if ( key == 'inconsistencyProfitLoss' ) { this.setState({inconsistencyProfitLoss: Number(value)}) }
      if ( key == 'misalignmentProfitLoss' ) { this.setState({misalignmentProfitLoss: Number(value)}) }
      if ( key == 'inefficiencyProfitLoss' ) { this.setState({inefficiencyProfitLoss: Number(value)}) }
      if ( key == 'totalAnnualRevenueLoss' ) { this.setState({totalAnnualRevenueLoss: Number(value)}) }
      if ( key == 'totalAnnualMarginLoss' ) { this.setState({totalAnnualMarginLoss: Number(value)}) }
      if ( key == 'totalProfitLoss' ) { this.setState({totalProfitLoss: Number(value)}) }
      if ( key == 'churnHighLow' ) { this.setState({churnHighLow: value}) }
      if ( key == 'crossSellHighLow' ) { this.setState({crossSellHighLow: value}) }
      if ( key == 'inconsistencyHighLow' ) { this.setState({inconsistencyHighLow: value}) }
      if ( key == 'misalignmentHighLow' ) { this.setState({misalignmentHighLow: value}) }
      if ( key == 'inefficiencyHighLow' ) { this.setState({inefficiencyHighLow: value}) }
    });
  }





  updateIndustry = (industrySelected, contentState) => {
    this.setState({
      industry: industrySelected,
      homeContentState: contentState
    });
    this.formData.industry = industrySelected;
  }


  generateShareLink = () => {
    let shareLink = window.location.origin + window.location.pathname + '?';
    shareLink += 'pageSelected=Results';
    shareLink += '&companyName=' + encodeURI(this.state.companyName);
    shareLink += '&industry=' + this.state.industry;
    shareLink += '&subIndustry=' + encodeURI(this.state.subIndustry);
    shareLink += '&revenue=' + this.state.revenue;
    shareLink += '&currency=' + this.state.currency;
    shareLink += '&churnScore=' + this.state.churnScore;
    shareLink += '&crossSellScore=' + this.state.crossSellScore;
    shareLink += '&inconsistencyScore=' + this.state.inconsistencyScore;
    shareLink += '&misalignmentScore=' + this.state.misalignmentScore;
    shareLink += '&inefficiencyScore=' + this.state.inefficiencyScore;
    shareLink += '&churnProfitLoss=' + this.state.churnProfitLoss;
    shareLink += '&crossSellProfitLoss=' + this.state.crossSellProfitLoss;
    shareLink += '&inconsistencyProfitLoss=' + this.state.inconsistencyProfitLoss;
    shareLink += '&misalignmentProfitLoss=' + this.state.misalignmentProfitLoss;
    shareLink += '&inefficiencyProfitLoss=' + this.state.inefficiencyProfitLoss;
    shareLink += '&totalAnnualRevenueLoss=' + this.state.totalAnnualRevenueLoss;
    shareLink += '&totalAnnualMarginLoss=' + this.state.totalAnnualMarginLoss;
    shareLink += '&totalProfitLoss=' + this.state.totalProfitLoss;
    shareLink += '&churnHighLow=' + this.state.churnHighLow;
    shareLink += '&crossSellHighLow=' + this.state.crossSellHighLow;
    shareLink += '&inconsistencyHighLow=' + this.state.inconsistencyHighLow;
    shareLink += '&misalignmentHighLow=' + this.state.misalignmentHighLow;
    shareLink += '&inefficiencyHighLow=' + this.state.inefficiencyHighLow;
    prompt('share link:', shareLink);
  }
  returnShareLink = () => {
    let shareLink = window.location.origin + window.location.pathname + '?';
    shareLink += 'pageSelected=Results';
    shareLink += '&companyName=' + encodeURI(this.state.companyName);
    shareLink += '&industry=' + this.state.industry;
    shareLink += '&subIndustry=' + encodeURI(this.state.subIndustry);
    shareLink += '&revenue=' + this.state.revenue;
    shareLink += '&currency=' + this.state.currency;
    shareLink += '&churnScore=' + this.state.churnScore;
    shareLink += '&crossSellScore=' + this.state.crossSellScore;
    shareLink += '&inconsistencyScore=' + this.state.inconsistencyScore;
    shareLink += '&misalignmentScore=' + this.state.misalignmentScore;
    shareLink += '&inefficiencyScore=' + this.state.inefficiencyScore;
    shareLink += '&churnProfitLoss=' + this.state.churnProfitLoss;
    shareLink += '&crossSellProfitLoss=' + this.state.crossSellProfitLoss;
    shareLink += '&inconsistencyProfitLoss=' + this.state.inconsistencyProfitLoss;
    shareLink += '&misalignmentProfitLoss=' + this.state.misalignmentProfitLoss;
    shareLink += '&inefficiencyProfitLoss=' + this.state.inefficiencyProfitLoss;
    shareLink += '&totalAnnualRevenueLoss=' + this.state.totalAnnualRevenueLoss;
    shareLink += '&totalAnnualMarginLoss=' + this.state.totalAnnualMarginLoss;
    shareLink += '&totalProfitLoss=' + this.state.totalProfitLoss;
    shareLink += '&churnHighLow=' + this.state.churnHighLow;
    shareLink += '&crossSellHighLow=' + this.state.crossSellHighLow;
    shareLink += '&inconsistencyHighLow=' + this.state.inconsistencyHighLow;
    shareLink += '&misalignmentHighLow=' + this.state.misalignmentHighLow;
    shareLink += '&inefficiencyHighLow=' + this.state.inefficiencyHighLow;
    return shareLink;
  }
  updateChurnProfitLoss = (score) =>{

    if(this.state.tabSectionComplete < 1){
      this.setState({tabSectionComplete:1})
    }
    this.setState({churnScore:score})
    let tempProfitLoss = (score/100)*this.formData.revenue
    this.setState({churnProfitLoss:tempProfitLoss},()=>{
      if(this.state.autoUpdateChurnUnlocked==true){
        this.displayTotalRevenueAndMarginLoss()
      }
    })
  }
  updateCrossSellProfitLoss = (score) =>{
    if(this.state.tabSectionComplete < 2){
      this.setState({tabSectionComplete:2})
    }
    this.setState({crossSellScore:score})
    let tempProfitLoss = (score/100)*this.formData.revenue
    this.setState({crossSellProfitLoss:tempProfitLoss},()=>{
      if(this.state.autoUpdateCrossSellUnlocked==true){
        this.displayTotalRevenueAndMarginLoss()
      }
    }
    )
  }


  updateInconsistencyProfitLoss = (score) =>{
    if(this.state.tabSectionComplete < 3){
      this.setState({tabSectionComplete:3})
    }
    this.setState({inconsistencyScore:score})
    let tempProfitLoss = (score/100)*this.formData.revenue
    this.setState({inconsistencyProfitLoss:tempProfitLoss},()=>{
      if(this.state.autoUpdateInconsistencyUnlocked==true){
        this.displayTotalRevenueAndMarginLoss()
      }
    })
  }
  updateMisalignmentProfitLoss = (score) =>{
    if(this.state.tabSectionComplete < 4){
      this.setState({tabSectionComplete:4})
    }
    this.setState({misalignmentScore:score})
    let tempProfitLoss = (score/100)*this.formData.revenue
    this.setState({misalignmentProfitLoss:tempProfitLoss},()=>{
      if(this.state.autoUpdateMisalignmentUnlocked==true){
        this.displayTotalRevenueAndMarginLoss()
      }
    })

  }
  updateInefficiencyProfitLoss = (score) =>{
    if(this.state.tabSectionComplete < 5){
      this.setState({tabSectionComplete:5})
    }
    this.setState({inefficiencyScore:score})
    let tempProfitLoss = (score/100)*this.formData.revenue
    this.setState({inefficiencyProfitLoss:tempProfitLoss},()=>{
      if(this.state.autoUpdateInefficiencyUnlocked==true){
        this.displayTotalRevenueAndMarginLoss()
      }
    })
  }

  updateHighLow = (category) => {
      if(category=='Churn'){
        if(this.state.autoUpdateChurnUnlocked==false){
          this.setState({autoUpdateChurnUnlocked:true})
        }
        this.setState({churnHighLow:this.highLow(this.state.churnScore,'Churn')},function(){

          if(this.state.churnHighLow=="High"){
            window.sectionCompleteHigh()
          }
          else if(this.state.churnHighLow=="Low"){
            window.sectionCompleteLow()
          }
          else{
            alert('empty')
          }
        })
    }
    else if(category=='Cross-Sell'){
      if(this.state.autoUpdateCrossSellUnlocked==false){
        this.setState({autoUpdateCrossSellUnlocked:true})
      }
      this.setState({crossSellHighLow:this.highLow(this.state.crossSellScore,'Cross-Sell')},function(){
        if(this.state.crossSellHighLow=="High"){
          window.sectionCompleteHigh()
        }
        else if(this.state.crossSellHighLow=="Low"){
          window.sectionCompleteLow()
        }
        else{
          alert('empty')
        }
      })
    }
    else if(category=='Inconsistency'){
      if(this.state.autoUpdateInconsistencyUnlocked==false){
        this.setState({autoUpdateInconsistencyUnlocked:true})
      }
      this.setState({inconsistencyHighLow:this.highLow(this.state.inconsistencyScore,'Inconsistency')},function(){
        if(this.state.inconsistencyHighLow=="High"){
          window.sectionCompleteHigh()
        }
        else if(this.state.inconsistencyHighLow=="Low"){
          window.sectionCompleteLow()
        }
        else{
          alert('empty')
        }
      })
    }
    else if(category=='Misalignment'){
      if(this.state.autoUpdateMisalignmentUnlocked==false){
        this.setState({autoUpdateMisalignmentUnlocked:true})
      }
      this.setState({misalignmentHighLow:this.highLow(this.state.misalignmentScore,'Misalignment')},function(){
        if(this.state.misalignmentHighLow=="High"){
          window.sectionCompleteHigh()
        }
        else if(this.state.misalignmentHighLow=="Low"){
          window.sectionCompleteLow()
        }
        else{
          alert('empty')
        }
      })
    }
    else if(category=='Inefficiency'){
      if(this.state.autoUpdateInefficiencyUnlocked==false){
        this.setState({autoUpdateInefficiencyUnlocked:true})
      }
      this.setState({inefficiencyHighLow:this.highLow(this.state.inefficiencyScore,'Inefficiency')},function(){
        if(this.state.inefficiencyHighLow=="High"){
          window.sectionCompleteHigh()
        }
        else if(this.state.inefficiencyHighLow=="Low"){
          window.sectionCompleteLow()
        }
        else{
          alert('empty')
        }
      })
    }
  }

  displayTotalRevenueAndMarginLoss = () =>{
    let tempTotalAnnualMarginLoss = this.state.inconsistencyProfitLoss+this.state.misalignmentProfitLoss+this.state.inefficiencyProfitLoss
    let tempTotalAnnualRevenueLoss = this.state.churnProfitLoss+this.state.crossSellProfitLoss
    tempTotalAnnualMarginLoss = Number(tempTotalAnnualMarginLoss).toFixed(2)
    tempTotalAnnualRevenueLoss = Number(tempTotalAnnualRevenueLoss).toFixed(2)

    this.setState({totalAnnualMarginLoss:tempTotalAnnualMarginLoss})
    this.setState({totalAnnualRevenueLoss:tempTotalAnnualRevenueLoss})
  }


  highLow = (score,category) =>{
    let lowPercent = 0
    let medianPercent = 0
    let highPercent = 0

    if(score==0){
      return ''
    }
    else{
      if ( this.state.industry == 'Manufacturing' ) {
        if (category == 'Churn' ) {
          lowPercent= 4.28
          medianPercent= 8.86
          highPercent= 16.12

        } else if (category == 'Cross-Sell' ) {
          lowPercent= 4.69
          medianPercent= 10.78
          highPercent= 20.76
        } else if (category == 'Inconsistency' ) {
          lowPercent= 1.05
          medianPercent= 4.06
          highPercent= 8.8
        } else if (category == 'Misalignment' ) {
          lowPercent= .51
          medianPercent= 2.12
          highPercent= 7.47
        } else if (category== 'Inefficiency' ) {
          lowPercent= .76
          medianPercent= 1.58
          highPercent= 2.4
        } // end if ( this.state.activeCategory == 'Churn' )
      } else if ( this.state.industry == 'Distribution' ) {
        if (category == 'Churn' ) {
          lowPercent= 4.33
          medianPercent= 9.08
          highPercent= 15.07
        } else if (category == 'Cross-Sell' ) {
          lowPercent= 2.92
          medianPercent= 7.09
          highPercent= 13.4
        } else if (category == 'Inconsistency' ) {
          lowPercent= 1.55
          medianPercent= 3.71
          highPercent= 7.57
        } else if (category == 'Misalignment' ) {
          lowPercent= .58
          medianPercent= 2.35
          highPercent= 5.73
        } else if (category == 'Inefficiency' ) {
          lowPercent= .76
          medianPercent= 1.58
          highPercent= 2.4
        } // end if ( this.state.activeCategory == 'Churn' )
      } else if ( this.state.industry == 'Other' ) {
        if (category == 'Churn' ) {
          lowPercent= 4.45
          medianPercent= 9.39
          highPercent= 16.07
        } else if (category == 'Cross-Sell' ) {
          lowPercent= 3.19
          medianPercent= 7.74
          highPercent= 15.67
        } else if (category== 'Inconsistency' ) {
          lowPercent= 1.37
          medianPercent= 3.96
          highPercent= 8.12
        } else if (category == 'Misalignment' ) {
          lowPercent= .52
          medianPercent= 2.09
          highPercent= 6.58
        } else if (category == 'Inefficiency' ) {
          lowPercent= .76
          medianPercent= 1.58
          highPercent= 2.4
        } // end if ( this.state.activeCategory == 'Churn' )
      }
      if(score < medianPercent){
        return "Low"
      }
      else{
        return "High"
      }
    }
  }



  updateFormData = (data) => {
    let tempObject = data
    this.formData.firstName = tempObject.firstName
    this.formData.lastName = tempObject.lastName
    this.formData.companyName = tempObject.companyName
    this.formData.emailAddress = tempObject.emailAddress
    this.formData.revenue = tempObject.revenue
    this.formData.subIndustry = tempObject.subIndustry
    this.setState({
      currency: data.currency,
      companyName: data.companyName,
      revenue: data.revenue,
      subIndustry: data.subIndustry
    });
  }

  updateContactFormData = (data) => {
    let tempObject = data
    this.formData.message = tempObject.message
  }



  selectPage = (value) => {
    this.setState({pageSelected:value},function() {
      let tempTotalAnnualRevenueLoss = this.state.churnProfitLoss+this.state.crossSellProfitLoss
      let tempTotalAnnualMarginLoss = this.state.inconsistencyProfitLoss+this.state.misalignmentProfitLoss+this.state.inefficiencyProfitLoss
      tempTotalAnnualRevenueLoss = Number(tempTotalAnnualRevenueLoss).toFixed(2)
      tempTotalAnnualMarginLoss = Number(tempTotalAnnualMarginLoss).toFixed(2)

      this.setState({totalAnnualRevenueLoss:tempTotalAnnualRevenueLoss})
      this.setState({totalAnnualMarginLoss:tempTotalAnnualMarginLoss})
    });
  }



  render() {

    if (this.state.pageSelected == "Home") {
      return (<Home
          industry={this.state.industry}
          updateIndustry={this.updateIndustry}
          updateFormData={this.updateFormData}
          selectPageFromRouting={this.selectPage}
          homeContentState={this.state.homeContentState}
      />);
    }


    else if (this.state.pageSelected == "Dashboard") {
      return (<Dashboard
          updateChurnProfitLoss = {this.updateChurnProfitLoss}
          updateCrossSellProfitLoss = {this.updateCrossSellProfitLoss}
          updateInconsistencyProfitLoss = {this.updateInconsistencyProfitLoss}
          updateMisalignmentProfitLoss = {this.updateMisalignmentProfitLoss}
          updateInefficiencyProfitLoss = {this.updateInefficiencyProfitLoss}
          currency={this.state.currency}

          industry={this.state.industry}

          formData={this.formData}
          returnShareLink={this.returnShareLink}

          churnHighLow ={this.state.churnHighLow}
          crossSellHighLow ={this.state.crossSellHighLow}
          inconsistencyHighLow ={this.state.inconsistencyHighLow}
          misalignmentHighLow ={this.state.misalignmentHighLow}
          inefficiencyHighLow ={this.state.inefficiencyHighLow}
          updateHighLow = {this.updateHighLow}

          churnProfitLoss = {this.state.churnProfitLoss}
          crossSellProfitLoss = {this.state.crossSellProfitLoss}
          inconsistencyProfitLoss = {this.state.inconsistencyProfitLoss}
          misalignmentProfitLoss = {this.state.misalignmentProfitLoss}
          inefficiencyProfitLoss = {this.state.inefficiencyProfitLoss}

          churnScore  = {this.state.churnScore}
          crossSellScore = {this.state.crossSellScore}
          inconsistencyScore = {this.state.inconsistencyScore}
          misalignmentScore = {this.state.misalignmentScore}
          inefficiencyScore = {this.state.inefficiencyScore}

          tabSectionComplete  = {this.state.tabSectionComplete}

          displayTotalRevenueAndMarginLoss = {this.displayTotalRevenueAndMarginLoss}

          companyName={this.state.companyName}
          selectPageFromRouting={this.selectPage}
          revenueData ={this.formData.revenue}
          totalAnnualRevenueLoss = {this.state.totalAnnualRevenueLoss}
          totalAnnualMarginLoss = {this.state.totalAnnualMarginLoss}
      />);
    }
    else if (this.state.pageSelected == "Results") {   
      return (<Results
          companyName={this.state.companyName}
          revenueData ={this.formData.revenue}
          revenue={this.state.revenue}
          industry={this.state.industry}
          subIndustry={this.state.subIndustry}
          currency={this.state.currency}
          formData={this.formData}

          updateContactFormData={this.updateContactFormData}

          generateShareLink={this.generateShareLink}
          returnShareLink={this.returnShareLink}

          totalAnnualRevenueLoss = {this.state.totalAnnualRevenueLoss}
          totalAnnualMarginLoss = {this.state.totalAnnualMarginLoss}

          churnProfitLoss = {this.state.churnProfitLoss}
          crossSellProfitLoss = {this.state.crossSellProfitLoss}
          inconsistencyProfitLoss = {this.state.inconsistencyProfitLoss}
          misalignmentProfitLoss = {this.state.misalignmentProfitLoss}
          inefficiencyProfitLoss = {this.state.inefficiencyProfitLoss}

          churnHighLow ={this.state.churnHighLow}
          crossSellHighLow ={this.state.crossSellHighLow}
          inconsistencyHighLow ={this.state.inconsistencyHighLow}
          misalignmentHighLow ={this.state.misalignmentHighLow}
          inefficiencyHighLow ={this.state.inefficiencyHighLow}

          churnScore  = {this.state.churnScore}
          crossSellScore = {this.state.crossSellScore}
          inconsistencyScore = {this.state.inconsistencyScore}
          misalignmentScore = {this.state.misalignmentScore}
          inefficiencyScore = {this.state.inefficiencyScore}
        />);
    }
  }//end render
}//end component
export default Routing;
