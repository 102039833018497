import React from 'react';
import {
  Grid, Typography
} from '@material-ui/core';

import FormattedNumber from '../../../FormattedNumber.js';

import Coin from '../../../../images/coin.js';

import numeral from 'numeral';

class ProfitLossPotential extends React.Component {
  constructor(props) {
    super();
  }



  render() {
    
    return(
      <div className={'card'} style={{padding: '24px'}}>
        <Grid container spacing={4} style={{alignItems: 'center'}}>
          <Grid item xs={4} style={{textAlign: 'center'}}>
            <div id="coin-wrapper">
              <Coin/>
            </div>
          </Grid>

          <Grid item xs={8}>

            <Typography variant="h2">
              <FormattedNumber
                number={this.props.totalAnnualRevenueLoss}
                currency={this.props.currency}
              />

            </Typography>
            <p style={{marginBottom: '30px', marginTop: '4px', color: 'rgba(0, 0, 0, 0.54)'}}>
              Total Annual Revenue Loss
            </p>

            <Typography variant="h2">
              <FormattedNumber
                number={this.props.totalAnnualMarginLoss}
                currency={this.props.currency}
              />
            </Typography>
            <p style={{marginTop: '4px', color: 'rgba(0, 0, 0, 0.54)'}}>
              Total Annual Margin Loss
            </p>

          </Grid>
        </Grid>

      </div>
    );
  }
}

export default ProfitLossPotential;
