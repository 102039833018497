import React from 'react';


function Inconsistency() {
  return(
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M28.5961 50.1963L9.39609 69.3963C7.51895 71.268 4.48123 71.268 2.60409 69.3963C2.19491 68.9842 1.86538 68.5001 1.63209 67.9683C0.804731 66.1527 1.19225 64.0142 2.60409 62.6043L21.8041 43.4043L26.1961 47.7963L28.5961 50.1963Z" fill="#FFB700"/>
      <path d="M26.1961 47.7963L6.99609 66.9963C6.09514 67.8962 4.87351 68.4013 3.60009 68.4003C2.92056 68.4004 2.2491 68.253 1.63209 67.9683C0.804731 66.1527 1.19225 64.0142 2.60409 62.6043L8.60409 56.6043L12.8521 52.3563L21.8041 43.4043L26.1961 47.7963Z" fill="#EE5C1C"/>
      <path d="M70.8 30.0002C70.796 45.9043 57.9041 58.7962 42 58.8002C41.388 58.8002 40.788 58.7762 40.2 58.7402C25.0472 57.7606 13.2581 45.1846 13.2581 30.0002C13.2581 14.8158 25.0472 2.23984 40.2 1.2602C40.788 1.2242 41.388 1.2002 42 1.2002C57.9041 1.20417 70.796 14.096 70.8 30.0002Z" fill="#FFB700"/>
      <path d="M40.1999 58.7398C55.1116 58.7398 67.1999 45.8724 67.1999 29.9998C67.1999 14.1271 55.1116 1.25977 40.1999 1.25977C25.2883 1.25977 13.2 14.1271 13.2 29.9998C13.2 45.8724 25.2883 58.7398 40.1999 58.7398Z" fill="#EE5C1C"/>
      <path d="M66 30C65.9868 43.2494 55.2493 53.9868 42 54C41.388 54 40.788 53.976 40.2 53.928C27.7032 52.9578 18.0576 42.5343 18.0576 30C18.0576 17.4657 27.7032 7.04218 40.2 6.072C40.788 6.024 41.388 6 42 6C55.2493 6.01323 65.9868 16.7507 66 30Z" fill="#FFB700"/>
      <path d="M40.2 53.9283C52.4607 53.9283 62.4 43.2153 62.4 30.0003C62.4 16.7852 52.4607 6.07227 40.2 6.07227C27.9393 6.07227 18 16.7852 18 30.0003C18 43.2153 27.9393 53.9283 40.2 53.9283Z" fill="#EE5C1C"/>
      <path d="M41.9999 37.2001C40.6744 37.2001 39.5999 36.1256 39.5999 34.8001V31.1053C39.6097 29.3192 40.9236 27.8084 42.6911 27.5509C44.0151 27.3563 45.1982 26.6192 45.9564 25.5165C46.7145 24.4138 46.9793 23.0452 46.6871 21.7393C46.2931 19.9229 44.8746 18.5039 43.0583 18.1093C41.634 17.7902 40.1419 18.1354 39.0025 19.0477C37.8631 19.96 37.1999 21.3405 37.1999 22.8001C37.1999 24.1256 36.1254 25.2001 34.7999 25.2001C33.4744 25.2001 32.3999 24.1256 32.3999 22.8001C32.4012 17.8116 36.223 13.6553 41.1939 13.2365C46.1648 12.8176 50.6282 16.2759 51.4642 21.1939C52.3001 26.1119 49.23 30.8506 44.3999 32.0977V34.8001C44.3999 36.1256 43.3254 37.2001 41.9999 37.2001Z" fill="white"/>
      <path d="M42.0001 46.8C43.3256 46.8 44.4001 45.7255 44.4001 44.4C44.4001 43.0745 43.3256 42 42.0001 42C40.6746 42 39.6001 43.0745 39.6001 44.4C39.6001 45.7255 40.6746 46.8 42.0001 46.8Z" fill="white"/>
      <path d="M17.2446 56.7484L12.9968 60.9961L15.3965 63.3957L19.6442 59.148L17.2446 56.7484Z" fill="#FFB700"/>
      <path d="M12.8522 52.3558L8.60449 56.6035L12.9965 60.9955L17.2442 56.7478L12.8522 52.3558Z" fill="#EE5C1C"/>
      <path d="M2.60409 62.6042C1.19225 64.014 0.804731 66.1526 1.63209 67.9682C1.86538 68.5 2.19491 68.9841 2.60409 69.3962C3.96858 70.7566 6.01314 71.1717 7.80009 70.451C7.20246 70.2105 6.65959 69.8517 6.20409 69.3962C5.79491 68.9841 5.46538 68.5 5.23209 67.9682C4.40473 66.1526 4.79225 64.014 6.20409 62.6042L12.2041 56.6042L22.1269 46.6802C16.0723 38.1632 15.1075 27.0344 19.606 17.6025C24.1045 8.17065 33.3601 1.91645 43.7893 1.2614C43.2001 1.2242 42.6001 1.2002 42.0001 1.2002C41.4001 1.2002 40.7881 1.2242 40.2001 1.2602C29.7685 1.91134 20.509 8.16431 16.0079 17.5973C11.5067 27.0302 12.4709 38.1616 18.5269 46.6802L8.60409 56.6042L2.60409 62.6042Z" fill="white"/>
      <path d="M42 38.3999C40.0117 38.3999 38.4 36.7881 38.4 34.7999V31.105C38.4118 28.7223 40.1641 26.7063 42.522 26.3626C43.5032 26.2174 44.3813 25.6744 44.9496 24.8614C45.531 24.0221 45.7351 22.9779 45.5123 21.9814C45.2199 20.6306 44.1653 19.5751 42.8148 19.2814C41.7435 19.0354 40.6185 19.2911 39.759 19.9761C38.8994 20.6612 38.3991 21.7007 38.4 22.7998C38.4 24.7881 36.7882 26.3998 34.8 26.3998C32.8117 26.3998 31.2 24.7881 31.2 22.7998C31.2004 17.3076 35.3227 12.6904 40.7797 12.0699C46.2368 11.4493 51.2905 15.0232 52.524 20.375C53.7576 25.7269 50.7781 31.1523 45.6 32.983V34.7999C45.6 36.7881 43.9882 38.3999 42 38.3999ZM41.9832 16.7998C42.4268 16.8004 42.8692 16.847 43.3031 16.939C45.5856 17.4337 47.3685 19.2166 47.8632 21.499C48.223 23.1294 47.8887 24.8359 46.9402 26.21C45.9918 27.584 44.5147 28.5018 42.8628 28.7434C41.6849 28.9106 40.8072 29.9154 40.8 31.105V34.7999C40.8 35.4626 41.3372 35.9998 42 35.9998C42.6627 35.9998 43.2 35.4626 43.2 34.7999V32.0962C43.1999 31.5495 43.5695 31.0719 44.0988 30.9346C48.3255 29.844 51.0125 25.6975 50.2813 21.394C49.55 17.0904 45.6443 14.0641 41.2945 14.4307C36.9447 14.7973 33.6006 18.4346 33.6 22.7998C33.6 23.4626 34.1372 23.9998 34.8 23.9998C35.4627 23.9998 36 23.4626 36 22.7998C35.9966 20.9909 36.8118 19.2775 38.2176 18.139C39.2793 17.2692 40.6106 16.7957 41.9832 16.7998Z" fill="black" fillOpacity="0.87"/>
      <path d="M41.9999 47.9998C40.0117 47.9998 38.3999 46.388 38.3999 44.3998C38.3999 42.4116 40.0117 40.7998 41.9999 40.7998C43.9881 40.7998 45.5999 42.4116 45.5999 44.3998C45.5999 46.388 43.9881 47.9998 41.9999 47.9998ZM41.9999 43.1998C41.3372 43.1998 40.7999 43.7371 40.7999 44.3998C40.7999 45.0625 41.3372 45.5998 41.9999 45.5998C42.6626 45.5998 43.1999 45.0625 43.1999 44.3998C43.1999 43.7371 42.6626 43.1998 41.9999 43.1998Z" fill="black" fillOpacity="0.87"/>
      <path d="M12 30C12.033 19.1959 17.8477 9.23626 27.24 3.8964C31.7504 1.36809 36.8293 0.0273349 42 0V2.4C37.2452 2.42814 32.5752 3.6618 28.4268 5.9856C19.7845 10.8973 14.4329 20.0596 14.4 30H12Z" fill="white"/>
      <path d="M13.8252 24.0424C13.5096 23.9743 13.1798 24.0423 12.9169 24.2299C12.654 24.4174 12.4823 24.707 12.444 25.0276C12.2924 25.847 12.1843 26.6737 12.12 27.5044L12 30.0004H14.4L14.52 27.7024C14.577 26.9377 14.6755 26.1765 14.8152 25.4224C14.8867 24.7769 14.4596 24.1815 13.8252 24.0424Z" fill="black" fillOpacity="0.87"/>
      <path d="M42.0002 2.4V0C36.8295 0.0273349 31.7506 1.36809 27.2402 3.8964C22.743 6.44473 18.9813 10.1127 16.3202 14.544L15.6722 15.6132C15.52 15.9013 15.4897 16.2383 15.588 16.5489C15.6864 16.8594 15.9051 17.1176 16.1954 17.2656C16.771 17.5561 17.4732 17.3339 17.777 16.7652L18.377 15.7836C20.8255 11.7052 24.2877 8.32985 28.427 5.9856C32.5753 3.6618 37.2453 2.42814 42.0002 2.4Z" fill="black" fillOpacity="0.87"/>
      <path d="M13.6729 20.1889C13.5631 20.494 13.5734 20.8295 13.7017 21.1273L13.8313 21.3229C13.8832 21.3869 13.9433 21.4438 14.0101 21.4921C14.0659 21.5457 14.1294 21.5906 14.1985 21.6253C14.2711 21.6613 14.3461 21.6921 14.4229 21.7177C14.8823 21.8523 15.3782 21.7124 15.6997 21.3577C16.0182 21.0138 16.0961 20.511 15.8965 20.0869C15.8248 19.9465 15.7284 19.8201 15.6121 19.7137C15.499 19.604 15.3657 19.5173 15.2197 19.4581C14.9233 19.3447 14.5941 19.3534 14.3041 19.4821C14.0135 19.6315 13.7886 19.8833 13.6729 20.1889Z" fill="black" fillOpacity="0.87"/>
      <path d="M42 60C25.4391 59.9815 12.0185 46.5609 12 30C12 29.3373 12.5373 28.8 13.2 28.8C13.8627 28.8 14.4 29.3373 14.4 30C14.4 45.2431 26.7569 57.6 42 57.6C57.2431 57.6 69.6 45.2431 69.6 30C69.6 14.7569 57.2431 2.4 42 2.4C41.3373 2.4 40.8 1.86274 40.8 1.2C40.8 0.537258 41.3373 0 42 0C58.5685 0 72 13.4315 72 30C72 46.5685 58.5685 60 42 60Z" fill="black" fillOpacity="0.87"/>
      <path d="M42 55.1998C28.0825 55.1998 16.8 43.9174 16.8 29.9998C16.8 16.0822 28.0825 4.7998 42 4.7998C55.9176 4.7998 67.2 16.0822 67.2 29.9998C67.1842 43.9108 55.911 55.1839 42 55.1998ZM42 7.1998C29.408 7.1998 19.2 17.4077 19.2 29.9998C19.2 42.5919 29.408 52.7998 42 52.7998C54.5921 52.7998 64.8 42.5919 64.8 29.9998C64.7862 17.4135 54.5864 7.21369 42 7.1998Z" fill="black" fillOpacity="0.87"/>
      <path d="M6.00003 72.0004C3.57217 72.0018 1.38273 70.5399 0.453434 68.297C-0.475863 66.054 0.0381605 63.472 1.75563 61.756L17.6796 45.832C18.1505 45.3772 18.8989 45.3837 19.3618 45.8466C19.8247 46.3095 19.8312 47.0579 19.3764 47.5288L3.45243 63.4528C2.04849 64.861 2.04849 67.1397 3.45243 68.548C4.87973 69.9051 7.12033 69.9051 8.54764 68.548L24.4716 52.624C24.7729 52.312 25.2191 52.1869 25.6386 52.2967C26.0582 52.4066 26.3859 52.7342 26.4957 53.1538C26.6055 53.5733 26.4804 54.0195 26.1684 54.3208L10.2444 70.2448C9.12091 71.3734 7.59253 72.0056 6.00003 72.0004Z" fill="black" fillOpacity="0.87"/>
      <path d="M15.396 64.5962C15.0777 64.5961 14.7725 64.4697 14.5476 64.2446L7.75556 57.4526C7.30079 56.9817 7.30729 56.2333 7.77017 55.7704C8.23305 55.3075 8.9815 55.301 9.45236 55.7558L16.2444 62.5478C16.5874 62.891 16.6901 63.407 16.5044 63.8554C16.3187 64.3037 15.8812 64.5961 15.396 64.5962Z" fill="black" fillOpacity="0.87"/>
      <path d="M19.6439 60.3482C19.3262 60.349 19.0211 60.2239 18.7955 60.0001L11.9999 53.2046C11.5452 52.7337 11.5517 51.9853 12.0146 51.5224C12.4774 51.0595 13.2259 51.053 13.6967 51.5078L20.4887 58.2998C20.8318 58.6429 20.9344 59.159 20.7488 59.6073C20.5631 60.0557 20.1256 60.348 19.6403 60.3482H19.6439Z" fill="black" fillOpacity="0.87"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="72" height="72" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Inconsistency;