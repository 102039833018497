import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';


class Tabs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      disableTabTwo:true,
      disableTabThree:true,
      disableTabFour:true,
      disableTabFive:true,
    }
  }
//tabSectionComplete

imageName = () => {
  return "./in-progress.png"
}
onComponenMount(){
}

  render() {
    if(this.props.tabSectionComplete==1){
      this.state.disableTabTwo = false;

    }
    else if(this.props.tabSectionComplete==2){
        this.state.disableTabThree = false;
    }
    else if(this.props.tabSectionComplete==3){
        this.state.disableTabFour = false;
    }
    else if(this.props.tabSectionComplete==4){
        this.state.disableTabFive = false;
    }
    return(
      <div className="card" id="tabs">
        <List id="tabs-list">
          <ListItem
            button
            onClick={()=>{this.props.selectChurnTab()}} 
            className={this.props.tab1}
            data-completed={this.props.tab1Completed ? 'completed' : ''}
          >
            <ListItemIcon>
              <img src={this.props.tab1Image} />
            </ListItemIcon>
            <ListItemText primary={'Churn'} secondary={this.props.churnHighLow} className={'nowrap'} />
          </ListItem>

          <ListItem
            button
            onClick={()=>{this.props.selectCrossSellTab()}} disabled={this.state.disableTabTwo} 
            className={this.props.tab2}
            data-completed={this.props.tab2Completed ? 'completed' : ''}
          >
            <ListItemIcon>
              <img src={this.props.tab2Image} />
            </ListItemIcon>
            <ListItemText primary={'Cross-Sell'} secondary={this.props.crossSellHighLow}className={'nowrap'} />
          </ListItem>

          <ListItem
            button
            onClick={()=>{this.props.selectInconsistencyTab()}} disabled={this.state.disableTabThree} 
            className={this.props.tab3}
            data-completed={this.props.tab3Completed ? 'completed' : ''}
          >
            <ListItemIcon>
              <img src={this.props.tab3Image} />
            </ListItemIcon>
            <ListItemText primary={'Inconsistency'} secondary={this.props.inconsistencyHighLow} className={'nowrap'} />
          </ListItem>

          <ListItem
            button
            onClick={()=>{this.props.selectMisalignmentTab()}} disabled={this.state.disableTabFour}
            className={this.props.tab4}
            data-completed={this.props.tab4Completed ? 'completed' : ''}
          >
            <ListItemIcon>
              <img src={this.props.tab4Image} />
            </ListItemIcon>
            <ListItemText primary={'Misalignment'} secondary={this.props.misalignmentHighLow} className={'nowrap'} />
          </ListItem>

          <ListItem
            button
            onClick={()=>{this.props.selectInefficiencyTab()}} disabled={this.state.disableTabFive} 
            className={this.props.tab5} 
            data-completed={this.props.tab5Completed ? 'completed' : ''}
          >
            <ListItemIcon>
              <img src={this.props.tab5Image} />
            </ListItemIcon>
            <ListItemText primary={'Inefficiency'} secondary={this.props.inefficiencyHighLow} className={'nowrap'} />
          </ListItem>

        </List>
      </div>
    );
  }
}

export default Tabs;
