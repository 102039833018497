import React from 'react';


function Churn() {
  return(
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path d="M54.6206 49.4665L71.4269 33.5605" stroke="#FFB700" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M54.6206 17.6543L71.4269 33.5603" stroke="#FFB700" strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M71.4269 33.5605H37.8142" stroke="#FFB700" strokeWidth="1.5" strokeLinecap="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.8116 11.0265L0 1.74805V62.721L30.8116 71.9995V11.0265Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M19.6074 8.37554L0 1.74805V62.721L19.6074 69.3485V8.37554Z" fill="black" fillOpacity="0.54"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.6101 11.0265L4.20166 1.74805V62.721L26.6101 71.9995V11.0265Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.20166 1.74805L35.0133 11.0265V62.721H51.8196V40.1875V24.2815V1.74805H4.20166Z" fill="#FFB700"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1.74805L30.8116 11.0265V62.721H47.6179V40.1875V24.2815V1.74805H0Z" fill="#FFB700"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1.74805L30.8116 11.0265V62.721H43.4163V40.1875V24.2815V1.74805H0Z" fill="#FFB700"/>
      <path d="M54.6206 48.1413L71.4269 32.2354" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M54.6206 16.3291L71.4269 32.2351" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M71.4269 32.2354H37.8142" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.8115 62.721H47.6178V40.1875" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M47.6179 24.2815V1.74805H0V62.721" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M30.8116 11.0265L0 1.74805V62.721L30.8116 71.9995V11.0265Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="72" height="72" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Churn;
