import { createMuiTheme }  from '@material-ui/core/styles'

// theme defaults: https://material-ui.com/customization/default-theme/
const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    fontFamily: "stratos",
    h2: {
      fontSize: '28px',
      lineHeight: '34px',
      fontWeight: 600
    },
    h3: {
      fontSize: '24px',
      lineHeight: '29px'
    },
    h4: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: '600'
    },
    h5: {
      fontWeight: '600',
      fontSize: '20px',
      lineHeight: '24px'
    },
    h6: {
      fontWeight: '600',
      fontSize: '17px',
      lineHeight: '20px'
    }
  },
});

export default theme