import React from 'react';
import Helmet from "react-helmet";
import {
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
  Checkbox
} from '@material-ui/core';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

// import TopBar from './contentAreaComponents/TopBar.js';

import Iframe from 'react-iframe';

import PhoneIcon from '@material-ui/icons/Phone';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import ChurnIcon from '../../images/churn.js';
import CrossSellIcon from '../../images/cross-sell.js';
import InconsistencyIcon from '../../images/inconsistency.js';
import MisalignmentIcon from '../../images/misalignment.js';
import InefficiencyIcon from '../../images/inefficiency.js';

import ProfitLossPotential from './contentAreaComponents/ProfitLossPotential';
import CategoryResults from '../CategoryResults';

class ResultsContentArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lowRevenue: false,
      showCal: false,
      showContact: false,
      message: '',
      formData:{},
      intakeFormUrl: '',
      showContactCard: Number(this.props.revenue) >= 150000000 ? true : false
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitToMarketo = this.submitToMarketo.bind(this);
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    this.props.updateContactFormData(this.state.formData);
  }


  submitToMarketo = () => {
    window.submitContactForm(this.props.formData);
    this.setState({showContact:false});
  }

  componentDidMount() {
    if ( this.props.industry=='Manufacturing' ) {
      this.setState({
        intakeFormUrl: '/manufacturingForm.html'
      });
    } else if ( this.props.industry=='Distribution' ) {
      this.setState({
        intakeFormUrl: '/distributionForm.html'
      });
    } else {
      this.setState({
        intakeFormUrl: '/otherForm.html'
      });
    }

    setTimeout(() => {
      this.props.formData.shareLink = this.props.returnShareLink();
      window.submitIntakeForm(this.props.formData);
    }, 1000);
  }

  render() {

    return(
      <>
        <Iframe
          url={this.state.intakeFormUrl}
          width="450px"
          height="450px"
          id="marketo-intake-form"
          display="none"
          position="relative"
        />
        
        <div style={{backgroundColor: '#f9f9fc'}}>
          <div id="calendly-lightbox-wrapper" style={{display: this.state.showCal ? 'block' : 'none' }}>
            <div id="calendly-lightbox-background"
              onClick={()=>this.setState({showCal:false})}></div>
            <div id="close-calendly-lightbox-wrapper"
              onClick={()=>this.setState({showCal:false})}>+</div>
            <div id="calendly-lightbox-inner-wrapper">
              <iframe title="calendly" width="100%" height="100%" src="https://calendly.com/zilliant_events/benchmark-quick-intake"></iframe>
            </div>
          </div>
          <div id="contact-me-lightbox-wrapper" style={{display: this.state.showContact ? 'block' : 'none' }}>
            <div id="contact-me-lightbox-background"
              onClick={()=>this.setState({showContact:false})}></div>
            <div id="close-contact-me-lightbox-wrapper"
              onClick={()=>this.setState({showContact:false})}
            >+</div>
            <div id="contact-me-lightbox-inner-wrapper">
              
              <Iframe
                url="/contactForm.html"
                width="450px"
                height="450px"
                id="marketo-contact-form"
                display="none"
                position="relative"
              />
              
              <Grid 
                container
                spacing={3} 
                align-items="center"  
                justify="center" 
              >
                <Grid item xs={6}>
                  <ValidatorForm
                    onSubmit={()=>{this.submitToMarketo()}}
                  >
                    <TextField
                      label="Message"
                      margin="normal"
                      name="message"
                      multiline
                      rows="3"
                      required
                      onChange={this.handleChange}
                      value={this.state.formData.message}
                      fullWidth
                    />
                    <Checkbox required/>
                    <p>
                      By checking this box, you agree that Zilliant may contact you to provide you with more information about its products, events, and offers. You can unsubscribe at any time. <a href={"https://www.zilliant.com/privacy-policy/"}>Privacy Policy Terms and Conditions</a>
                    </p>
                    <Button
                      id="contact-form-submit"
                      type="submit"
                      color="primary"
                    >Submit</Button>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </div>
          </div>
          <div style={{position: 'relative'}}>
            <Typography variant="h1">
              {this.props.companyName} Benchmark Report
            </Typography>
            <img
              src="/publicImages/share.svg"
              onClick={()=>{this.props.generateShareLink()}}
              id="share-button"
              alt="share button"
            />
            <p className="full-width" style={{margin: '16px 0', paddingRight: '10%'}}>
              The metrics below represent estimated margin and revenue loss, not all of which is capturable. Zilliant customers typically capture between 10 and 20 percent of the total loss, resulting in a 100 to 300 basis point improvement in gross margin and 5 to 15 percent increase in same customer sales, depending on your business.
            </p>
          </div>

          <Divider/>

          <Grid
            container
            direction="row"
            spacing={3}
            style={{marginTop: '48px'}}
          >
            <Grid item xs={12} lg={6}>
              <ProfitLossPotential
                totalAnnualMarginLoss={this.props.totalAnnualMarginLoss}
                totalAnnualRevenueLoss={this.props.totalAnnualRevenueLoss}
                currency={this.props.currency}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <div
                id="results-contact-card"
                className="card"
                style={{display: this.state.showContactCard ? 'grid' : 'none'}}
              >
                <p style={{marginTop: '0'}}>
                  Schedule time to talk to one of our sales directors about diving into your results or ask to be contacted at your convenience.
                </p>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      id="contact-me-button"
                      variant="outlined"
                      onClick={ ()=>this.setState({showContact:true}) }
                      fullWidth
                      startIcon={<PhoneIcon />}
                      className="white-button"
                    >
                      Contact me
                    </Button>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      id="calendly-button"
                      variant="outlined"
                      onClick={ ()=>this.setState({showCal:true}) }
                      fullWidth
                      startIcon={<CalendarTodayIcon />}
                      className="white-button"
                    >
                      Schedule
                    </Button>
                  </Grid>

                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="category-results" style={{marginTop: '12px'}}>
            <Grid item xs={6}>
              <CategoryResults
                currency={this.props.currency}
                highLowResultForCat={this.props.churnHighLow}
                title="Customer Churn"
                category="Churn"
                industry={this.props.industry}
                subIndustry={this.props.subIndustry}
                icon={<ChurnIcon/>}
                description="Customer churn measures accounts that defect to competitors and accounts with declining purchase volume in one or more product categories. View your company's estimated lost revenue and see how this loss as a percent of total revenue compares to the benchmarked companies in the chart below."
                totalProfitLossPotential={Number(this.props.churnProfitLoss).toFixed(2)}
                score={this.props.churnScore}
              />
            </Grid>
            <Grid item xs={6}>
              <CategoryResults
                currency={this.props.currency}
                highLowResultForCat={this.props.crossSellHighLow}
                title="Missed Cross-Sell"
                category="Cross-Sell"
                industry={this.props.industry}
                subIndustry={this.props.subIndustry}
                icon={<CrossSellIcon/>}
                description="Missed cross-sell accounts for lower-than-expected wallet share based on a customer's quantified spend potential. View your company's estimated lost revenue and see how this loss as a percent of total revenue compares to the benchmarked companies in the chart below."
                totalProfitLossPotential={Number(this.props.crossSellProfitLoss).toFixed(2)}
                score={this.props.crossSellScore}
              />
            </Grid>
            <Grid item xs={6}>
              <CategoryResults
                currency={this.props.currency}
                highLowResultForCat={this.props.inconsistencyHighLow}
                title="Inconsistent Pricing"
                category="Inconsistency"
                industry={this.props.industry}
                subIndustry={this.props.subIndustry}
                icon={<InconsistencyIcon/>}
                description="Prices that are inconsistent based on market, order, product or customer circumstances, including smaller customers receiving better prices than larger customers, or when too many transactions are over or underpriced. View your company's estimated lost margin and see how this loss as a percent of total revenue compares to the benchmarked companies in the chart below."
                totalProfitLossPotential={Number(this.props.inconsistencyProfitLoss).toFixed(2)}
                score={this.props.inconsistencyScore}
              />
            </Grid>
            <Grid item xs={6}>
              <CategoryResults
                currency={this.props.currency}
                highLowResultForCat={this.props.misalignmentHighLow}
                title="Misaligned Market Pricing"
                category="Misalignment"
                industry={this.props.industry}
                subIndustry={this.props.subIndustry}
                icon={<MisalignmentIcon/>}
                description="Failing to quickly and accurately update pricing when costs change or when, with a lack of contextual price guidance, sales reps arbitrarily round prices up or down. View your company's estimated lost margin and see how this loss as a percent of total revenue compares to the benchmarked companies in the chart below."
                totalProfitLossPotential={Number(this.props.misalignmentProfitLoss).toFixed(2)}
                score={this.props.misalignmentScore}
              />
            </Grid>
            <Grid item xs={6}>
              <CategoryResults
                currency={this.props.currency}
                highLowResultForCat={this.props.inefficiencyHighLow}
                title="Inefficient Pricing Practices"
                category="Inefficiency"
                industry={this.props.industry}
                subIndustry={this.props.subIndustry}
                icon={<InefficiencyIcon/>}
                description="An over-reliance on manual processes and calculations to update prices results in a slow response to pricing triggers and a lag in quote response time, creating inefficiency. View your company's estimated lost margin and see how this loss as a percent of total revenue compares to the benchmarked companies in the chart below."
                totalProfitLossPotential={Number(this.props.inefficiencyProfitLoss).toFixed(2)}
                score={this.props.inefficiencyScore}
              />
            </Grid>

          </Grid>
        </div>
      </>
    );
  }
}

export default ResultsContentArea;
