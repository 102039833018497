import React from 'react';

import {
  Typography,
  Button
} from '@material-ui/core';

import {
  ArrowForward,
  NavigateBefore
} from '@material-ui/icons';

import homeVideo from '../../videos/home.mp4';
import manufacturingVideo from '../../videos/manufacturing.mp4';
import distributionVideo from '../../videos/distribution.mp4';
import otherVideo from '../../videos/other.mp4';

import IntakeForm from './contentAreaComponents/IntakeForm.js';
import Manufacturing from '../../images/manufacturing.js';
import Distribution from '../../images/distribution.js';
import Other from '../../images/other.js';

class HomeContentArea extends React.Component {
    constructor(props) {
    super();
  }


  selectIndustry = (industrySelected, contentState) => {
    this.props.updateIndustry(industrySelected, contentState)
  }


  render() {

    const industry = {
      home: {
        title: "2020 Global B2B Benchmark Report",
        body: "<p>Most company leaders have a gnawing instinct that pricing and sales could be improved, and thus, lead to financial gains. Knowing precisely which processes, how to measure the loss, and most importantly, how to recapture this value is impossible with manual means.</p><p>Now, you can compare your company’s performance against the Zilliant 2020 Global B2B Benchmark Report with our interactive experience </p><p>At the end of the questionnaire, you will be able to download a personalized comparison report for your business. We just need a few pieces of information to select the right comparison group and calculate your results. Please select your industry to get started.</p>"
      },
      manufacturing: {
        title: "Manufacturers:<br/>How Does Your Company Measure Up?",
        body: "<p>Manufacturing business leaders are challenged to navigate increasingly turbulent waters to find the best, most reliable paths to profit and revenue growth. Fierce competitors, fluctuating demand, and volatile costs are just a handful of factors contributing to the growing storm.</p><p>An analysis of more than 1 billion B2B transactions, the fourth publication of the Zilliant Global B2B Benchmark Report, has revealed that, even in turbulent macroeconomic conditions, the impact of poor pricing and sales practices has remained relatively stable. This means that manufacturers can improve P&L performance by addressing pricing and sales practices.</p><p>Our interactive experience will ask for a few quantitative data points and three qualitative questions across five benchmark categories to reveal the total estimated revenue and margin loss your organization is likely experiencing annually and how you compare to other manufacturers.</p>"
      },
      distribution: {
        title: "Distributors:<br/>Discover How Your Company Compares",
        body: "<p>Distribution business leaders are challenged to navigate increasingly turbulent waters to find the best, most reliable paths to profit and revenue growth. Fierce competitors, fluctuating demand, product proliferation and volatile costs are just a handful of factors contributing to the growing storm.</p><p>An analysis of more than 1 billion B2B transactions, the fourth publication of the Zilliant Global B2B Benchmark Report, has revealed that, even in turbulent macroeconomic conditions, the impact of poor pricing and sales practices has remained relatively stable. This means that distributors can improve P&L performance by addressing pricing and sales practices.</p><p>Our interactive experience will ask for a few quantitative data points and three qualitative questions across five benchmark categories to reveal the total estimated revenue and margin loss your organization is likely experiencing annually and how you compare to other distributors.</p>"
      },
      other: {
        title: "Discover How Your Company Compares",
        body: "<p>Business leaders are challenged to navigate increasingly turbulent waters to find the best, most reliable paths to profit and revenue growth. Fierce competitors, fluctuating demand, product proliferation and volatile costs are just a handful of factors contributing to the growing storm.</p><p>An analysis of more than 1 billion B2B transactions, the fourth publication of the Zilliant Global B2B Benchmark Report, has revealed that, even in turbulent macroeconomic conditions, the impact of poor pricing and sales practices has remained relatively stable. This means that B2B companies can improve P&L performance by addressing pricing and sales practices</p><p>Our interactive experience will ask for a few quantitative data points and three qualitative questions across five benchmark categories to reveal the total estimated revenue and margin loss your organization is likely experiencing annually and how you compare to other B2B companies.</p>"
      }
    };

    const slideOverlayIn = () => {
      var overlay = document.querySelector('#slide-in-overlay');
      overlay.style.left = '0';
    };

    const slideOverlayOut = () => {
      var overlay = document.querySelector('#slide-in-overlay');
      overlay.style.left = '100%';
    };

    const changeSidebarIndustry = (industryType, contentState) => {
      const sidebarTitle = document.querySelector('#sidebar-title');
      const sidebarBody = document.querySelector('#sidebar-body');
      const sidebarVideo = document.querySelector('#sidebar-video');

      this.selectIndustry(industryType, contentState);

      slideOverlayIn();

      setTimeout(() => {
        if ( industryType == '' ) {
          sidebarTitle.innerHTML = industry.home.title;
          sidebarBody.innerHTML = industry.home.body;
          sidebarVideo.innerHTML = `<source type="video/mp4" src=${homeVideo}></source>`;
          sidebarVideo.load();
        } else if ( industryType == 'Manufacturing' ) {
          sidebarTitle.innerHTML = industry.manufacturing.title;
          sidebarBody.innerHTML = industry.manufacturing.body;
          sidebarVideo.innerHTML = `<source type="video/mp4" src=${manufacturingVideo}></source>`;
          sidebarVideo.load();
        } else if ( industryType == 'Distribution' ) {
          sidebarTitle.innerHTML = industry.distribution.title;
          sidebarBody.innerHTML = industry.distribution.body;
          sidebarVideo.innerHTML = `<source type="video/mp4" src=${distributionVideo}></source>`
          sidebarVideo.load();
        } else if ( industryType == 'Other' ) {
          sidebarTitle.innerHTML = industry.other.title;
          sidebarBody.innerHTML = industry.other.body;
          sidebarVideo.innerHTML = `<source type="video/mp4" src=${otherVideo}></source>`;
          sidebarVideo.load();
        }
      }, 140);

      setTimeout(() => {
        slideOverlayOut();
      }, 300);
    };

    

    if (this.props.homeContentState == "industrySelect") {

      return (
        <>
          <Typography variant="h2" style={{marginBottom: '48px'}}>
            Tell us about your company
          </Typography>

          <Button
            id="manufacturing-industry-button"
            className="large-icon-button"
            onClick={()=>{changeSidebarIndustry('Manufacturing', 'formFill')}}
            startIcon={<Manufacturing/>}
            endIcon={<ArrowForward/>}
          >Manufacturing</Button>

          <Button
            id="distribution-industry-button"
            className="large-icon-button"
            onClick={()=>{changeSidebarIndustry('Distribution', 'formFill')}}
            startIcon={<Distribution/>}
            endIcon={<ArrowForward/>}
          >Distribution</Button>

          <Button
            id="other-industry-button"
            className="large-icon-button"
            onClick={()=>{changeSidebarIndustry('Other', 'formFill')}}
            startIcon={<Other/>}
            endIcon={<ArrowForward/>}
          >Other</Button>

        </>
      );
    } else if (this.props.homeContentState == "formFill") {

      let subindustries = [];
      if ( this.props.industry == "Manufacturing" ) {

        subindustries = [
          "Building Products Manufacturing",
          "Electrical Products Manufacturing",
          "Electronic Component & High-Tech Manufacturing",
          "Manufacturing - Other",
          "Industrial Manufacturing",
          "Food Producers",
          "Fuel & Lubricants Manufacturing",
          "Chemicals Manufacturing"
        ];
      } else if (this.props.industry == "Distribution" ) {
        subindustries = [
          "Food Service Distribution",
          "MRO Distribution",
          "Chemicals Distribution",
          "Fuel & Lubricants Distribution",
          "Automotive & Truck Parts Distribution",
          "Electrical Products Distribution",
          "Electronic Components & High-Tech Distribution",
          "Building Products Distribution",
          "Metals",
          "Distribution - Other"
        ];
      } else if (this.props.industry == "Other" ) {
        subindustries = [
          "Equipment Rental",
          "Staffing Services",
          "Shipping & Transportation",
          "Services - Other"
        ];
      }



      return (
        <>
          <Button
            id="back-button"
            startIcon={<NavigateBefore/>}
            onClick={()=>{changeSidebarIndustry('', 'industrySelect')}}
            color="primary"
          >Back</Button>

          <Typography variant="h2">
            Let us personalize the benchmark report to your company's dynamics
          </Typography>
          <p>
            Conduct your own quantitative and qualitative analysis with our simple, interactive benchmark tool to discover the opportunity areas in your business. We just need a few pieces of information to begin:
          </p>

          <IntakeForm
            industry={this.props.industry}
            subindustries={subindustries}
            updateFormData={this.props.updateFormData}
            selectPageFromRouting={this.props.selectPageFromRouting}
          />
        </>
      );
    }
  } /* end render() */
} /* end class HomeContentArea */


export default HomeContentArea;
