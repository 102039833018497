import React from 'react';
import '../../styles/Home.css';
import {
  Grid
} from '@material-ui/core';
import Sidebar from '../SidebarRouting.js';
import ContentArea from '../ContentAreaRouting.js';

class Home extends React.Component {

  constructor() {
    super();
    this.state = {
      industry: ''
    };
  }

  render() {
    return(

      <Grid container>

        <Grid item className="sidebar" xs={12} md={4}>
          <Sidebar page="home" />
        </Grid>

        <Grid item className="content-area" id="home-content-area" xs={12} md={8}>
          <div
            id="home-content-area-inner-wrapper"
            className={this.props.industry != "" ? 'intake-form' : 'industry-select'}
          >
            <ContentArea
              updateIndustry={this.props.updateIndustry}
              updateFormData={this.props.updateFormData}
              industry={this.props.industry}
              selectPageFromRouting={this.props.selectPageFromRouting}
              page="home"
              homeContentState={this.props.homeContentState}
            />
          </div>
        </Grid>

      </Grid>
    );
  }
}

export default Home;
