import React from 'react';

import {
  Button,
  MenuItem,
  Grid
} from '@material-ui/core';
import { ValidatorForm, TextValidator, SelectValidator} from 'react-material-ui-form-validator';



class IntakeForm extends React.Component {
  constructor(props) {
    super();
    this.state = {
      homeContentState: "Dashboard",
      value:'',
      ready: false,
      formData:{},
      urlAddress:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    this.setState({ready : (Object.keys(this.state.formData).length >= 7)});
  }

  handleRevenueChange = (event) => {
    const { formData } = this.state;
    let revenue = event.target.value.replace(/\D/g, '');
    formData[event.target.name] = revenue;
    this.setState({ formData });
    this.setState({
      formattedRevenue: this.formatRevenue(revenue)
    });
    this.setState({ready : (Object.keys(this.state.formData).length >= 7)});
  }



  handleSubmit = () => {
    this.props.updateFormData(this.state.formData)

    this.props.selectPageFromRouting('Dashboard')
  }



  formatRevenue = (revenue) => {
    revenue = revenue.replace(/\D/g, '');
    var formattedRevenue = new Intl.NumberFormat('en-US').format(revenue);
    return formattedRevenue;
  }



  componentDidMount() {
    var revenueButton = document.querySelector('#annual-revenue-button');
    revenueButton.addEventListener('keyup', () => {
      revenueButton.value = this.formatRevenue(revenueButton.value);
    });
  }


  render(props) {

    const { formData } = this.state;


    return(
      <>
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
      >
        <TextValidator
          label="First Name"
          margin="normal"
          name="firstName"
          value={formData.firstName}
          onChange={this.handleChange}
          fullWidth
          validators={['required', 'isString']}
          errorMessages={['First Name is required', 'Invalid Input']}
        />

        <TextValidator
          label="Last Name"
          margin="normal"
          name="lastName"
          value={formData.lastName}
          onChange={this.handleChange}
          fullWidth
          validators={['required', 'isString']}
          errorMessages={['Last Name is required', 'Invalid Input']}
        />

        <TextValidator
          label="Company Name"
          margin="normal"
          name="companyName"
          value={formData.companyName}
          onChange={this.handleChange}
          fullWidth
          validators={['required', 'isString']}
          errorMessages={['Company Name is required', 'Invalid Input']}
        />

        <TextValidator
          type="email"
          margin="normal"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={this.handleChange}
          fullWidth
          label="Work Email"
          validators={['required', 'isEmail']}
          errorMessages={['A valid e-mail address is required', 'Invalid Input']}
        />

        <Grid container spacing={3} style={{marginTop: '5px', marginBottom: '13px'}}>
          <Grid item xs={6}>
            <SelectValidator
              label="Currency"
              name="currency"
              fullWidth
              onChange={this.handleChange}
              value={formData.currency}
              validators={['required']}
              errorMessages={['Currency is required']}
            >
              <MenuItem value="USD" selected>US Dollars (USD)</MenuItem>
              <MenuItem value="EUR">Euros (EUR)</MenuItem>
            </SelectValidator>
          </Grid>

          <Grid item xs={6}>
            <TextValidator
              id="annual-revenue-button"
              label="Annual Revenue"
              name="revenue"
              value={this.state.formattedRevenue}
              onChange={this.handleRevenueChange}
              validators={['required']}
              errorMessages={['Annual Revenue is required']}
            />
          </Grid>
        </Grid>


        <SelectValidator
          label="Sub-Industry"
          name="subIndustry"
          fullWidth
          onChange={this.handleChange}
          value={formData.subIndustry}
          id="subindustry-select"
          validators={['required']}
          errorMessages={['Sub-Industry is required']}
        >
          {this.props.subindustries.map((menuItem, index) => {
            return(
              <MenuItem key={index} value={menuItem}>{menuItem}</MenuItem>
            );
          })}
        </SelectValidator>

        <Button
          id="intake-form-submit"
          submit-state={this.state.ready ? 'ready' : 'not-ready'}
          type="submit"
          disabled={!this.state.ready}
        >Submit</Button>

      </ValidatorForm>
      </>
    );
  }
}

export default IntakeForm;
