import React from 'react';
import Logo from '../../images/logo-white.js';

function DashboardSidebar() {
  return(
    <div id="dashboard-sidebar">
      <Logo/>
    </div>
  );
}
export default DashboardSidebar;
