import React from 'react';


function Inefficiency() {
  return(
    <svg width="63" height="72" viewBox="0 0 63 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.2 70.7998C49.1116 70.7998 61.2 57.3683 61.2 40.7998C61.2 24.2313 49.1116 10.7998 34.2 10.7998C19.2883 10.7998 7.19995 24.2313 7.19995 40.7998C7.19995 57.3683 19.2883 70.7998 34.2 70.7998Z" fill="#FFB700"/>
      <path d="M28.2 70.7998C43.1116 70.7998 55.2 57.3683 55.2 40.7998C55.2 24.2313 43.1116 10.7998 28.2 10.7998C13.2883 10.7998 1.19995 24.2313 1.19995 40.7998C1.19995 57.3683 13.2883 70.7998 28.2 70.7998Z" fill="white"/>
      <path d="M31.2 70.7998C45.1176 70.7998 56.4 57.3683 56.4 40.7998C56.4 24.2313 45.1176 10.7998 31.2 10.7998C17.2824 10.7998 6 24.2313 6 40.7998C6 57.3683 17.2824 70.7998 31.2 70.7998Z" fill="#EE5C1C"/>
      <path d="M50.5323 19.0684C50.047 19.0683 49.6096 18.7759 49.4239 18.3275C49.2382 17.8792 49.3408 17.3632 49.6839 17.02L53.1515 13.5518C53.6213 13.0904 54.3751 13.0939 54.8406 13.5594C55.3062 14.025 55.3096 14.7788 54.8483 15.2486L51.3807 18.7168C51.1559 18.9422 50.8506 19.0687 50.5323 19.0684Z" fill="#FFB700"/>
      <path d="M56.3999 17.9998C56.0816 18.0002 55.7763 17.8736 55.5515 17.6482L50.7515 12.8482C50.2902 12.3784 50.2936 11.6246 50.7591 11.1591C51.2247 10.6935 51.9785 10.6901 52.4483 11.1514L57.2483 15.9514C57.5914 16.2946 57.694 16.8106 57.5083 17.259C57.3226 17.7073 56.8852 17.9997 56.3999 17.9998Z" fill="#FFB700"/>
      <path d="M11.8675 19.0684C11.5492 19.0687 11.2439 18.9422 11.0191 18.7168L7.55155 15.2486C7.0902 14.7788 7.09362 14.025 7.5592 13.5594C8.02478 13.0939 8.77857 13.0904 9.24835 13.5518L12.7159 17.02C13.059 17.3632 13.1616 17.8792 12.9759 18.3275C12.7902 18.7759 12.3528 19.0683 11.8675 19.0684Z" fill="#FFB700"/>
      <path d="M6.00007 17.9998C5.5148 17.9997 5.07735 17.7073 4.89166 17.259C4.70597 16.8106 4.80858 16.2946 5.15167 15.9514L9.95167 11.1514C10.4214 10.6901 11.1752 10.6935 11.6408 11.1591C12.1064 11.6246 12.1098 12.3784 11.6485 12.8482L6.84847 17.6482C6.62372 17.8736 6.31839 18.0002 6.00007 17.9998Z" fill="#FFB700"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.2 66.0006C17.2824 66.0006 6 54.7182 6 40.8006C6 26.883 17.2824 15.6006 31.2 15.6006C45.1176 15.6006 56.4 26.883 56.4 40.8006C56.3842 54.7116 45.111 65.9848 31.2 66.0006Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.6 10.908V6H28.8V10.908" fill="#EE5C1C"/>
      <path d="M26.4 1.2002H36C37.3255 1.2002 38.4 2.27471 38.4 3.6002V6.0002H24V3.6002C24 2.27471 25.0745 1.2002 26.4 1.2002Z" fill="#EE5C1C"/>
      <path d="M31.2001 44.4002C33.1883 44.4002 34.8001 42.7884 34.8001 40.8002C34.8001 38.812 33.1883 37.2002 31.2001 37.2002C29.2119 37.2002 27.6001 38.812 27.6001 40.8002C27.6001 42.7884 29.2119 44.4002 31.2001 44.4002Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 15.6006V19.2006" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 62.4004V66.0004" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M49.0192 22.9814L46.4736 25.527" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9264 56.0732L13.3809 58.6188" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M56.4001 40.7998H52.8" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.6 40.7998H6" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M49.0192 58.6188L46.4736 56.0732" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9264 25.527L13.3809 22.9814" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 32.4V24" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M43.0801 28.9199L37.1401 34.8599" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M43.08 28.92C39.9296 25.7687 35.6559 23.9988 31.2 24" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M37.1399 34.8604C35.5663 33.2824 33.4285 32.3971 31.2 32.4004" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M50.5323 19.0684C50.047 19.0683 49.6096 18.7759 49.4239 18.3275C49.2382 17.8792 49.3408 17.3632 49.6839 17.02L53.1515 13.5518C53.6213 13.0904 54.3751 13.0939 54.8406 13.5594C55.3062 14.025 55.3096 14.7788 54.8483 15.2486L51.3807 18.7168C51.1559 18.9422 50.8506 19.0687 50.5323 19.0684Z" fill="#FFB700"/>
      <path d="M56.3999 17.9998C56.0816 18.0002 55.7763 17.8736 55.5515 17.6482L50.7515 12.8482C50.2902 12.3784 50.2936 11.6246 50.7591 11.1591C51.2247 10.6935 51.9785 10.6901 52.4483 11.1514L57.2483 15.9514C57.5914 16.2946 57.694 16.8106 57.5083 17.259C57.3226 17.7073 56.8852 17.9997 56.3999 17.9998Z" fill="#FFB700"/>
      <path d="M11.8675 19.0684C11.5492 19.0687 11.2439 18.9422 11.0191 18.7168L7.55155 15.2486C7.0902 14.7788 7.09362 14.025 7.5592 13.5594C8.02478 13.0939 8.77857 13.0904 9.24835 13.5518L12.7159 17.02C13.059 17.3632 13.1616 17.8792 12.9759 18.3275C12.7902 18.7759 12.3528 19.0683 11.8675 19.0684Z" fill="#FFB700"/>
      <path d="M6.00007 17.9998C5.5148 17.9997 5.07735 17.7073 4.89166 17.259C4.70597 16.8106 4.80858 16.2946 5.15167 15.9514L9.95167 11.1514C10.4214 10.6901 11.1752 10.6935 11.6408 11.1591C12.1064 11.6246 12.1098 12.3784 11.6485 12.8482L6.84847 17.6482C6.62372 17.8736 6.31839 18.0002 6.00007 17.9998Z" fill="#FFB700"/>
      <path d="M33.6 4.93555H28.8V11.2505H33.6V4.93555Z" fill="#FFB700"/>
      <path d="M56.4 40.8006C56.4 41.2086 56.388 41.6046 56.364 42.0006C56.0912 47.8472 53.7868 53.4153 49.848 57.7446C49.296 58.3326 48.732 58.8966 48.144 59.4486H48.132C43.8103 63.3918 38.2442 65.6972 32.4 65.9646C32.004 65.9886 31.608 66.0006 31.2 66.0006C30.792 66.0006 30.396 65.9886 30 65.9646C29.592 65.9526 29.196 65.9166 28.8 65.8806C23.3858 65.3724 18.2845 63.1145 14.268 59.4486H14.256C13.668 58.8966 13.104 58.3326 12.552 57.7446C8.61325 53.4153 6.30876 47.8472 6.036 42.0006C6.012 41.6046 6 41.2086 6 40.8006C6 40.3926 6.012 39.9966 6.036 39.6006C6.30875 33.754 8.61323 28.1859 12.552 23.8566C13.104 23.2686 13.668 22.7046 14.256 22.1526C18.2832 18.4958 23.3851 16.2395 28.8 15.7206C29.196 15.6846 29.592 15.6486 30 15.6366C30.396 15.6126 30.792 15.6006 31.2 15.6006C31.608 15.6006 32.004 15.6126 32.4 15.6366C38.2466 15.9094 43.8146 18.2139 48.144 22.1526C48.732 22.7046 49.296 23.2686 49.848 23.8566C53.7868 28.1859 56.0913 33.754 56.364 39.6006C56.388 39.9966 56.4 40.3926 56.4 40.8006Z" fill="#EE5C1C"/>
      <path d="M51.6 40.7997C51.6 41.2077 51.588 41.6037 51.564 41.9997C51.2912 47.8463 48.9868 53.4144 45.048 57.7437C44.496 58.3317 43.932 58.8957 43.344 59.4477H43.332C39.3155 63.1137 34.2142 65.3715 28.8 65.8797C23.3858 65.3715 18.2845 63.1137 14.268 59.4477H14.256C13.668 58.8957 13.104 58.3317 12.552 57.7437C8.61325 53.4144 6.30876 47.8463 6.036 41.9997C6.012 41.6037 6 41.2077 6 40.7997C6 40.3917 6.012 39.9957 6.036 39.5997C6.30875 33.7532 8.61323 28.185 12.552 23.8557C13.104 23.2677 13.668 22.7037 14.256 22.1517C18.2832 18.4949 23.3851 16.2386 28.8 15.7197C34.2149 16.2386 39.3168 18.4949 43.344 22.1517C43.932 22.7037 44.496 23.2678 45.048 23.8557C48.9868 28.185 51.2913 33.7532 51.564 39.5997C51.588 39.9957 51.6 40.3917 51.6 40.7997Z" fill="#EE5C1C"/>
      <path d="M26.4 1.2002H36C37.3255 1.2002 38.4 2.27471 38.4 3.6002V6.0002H24V3.6002C24 2.27471 25.0745 1.2002 26.4 1.2002Z" fill="#EE5C1C"/>
      <path d="M31.2001 44.4002C33.1883 44.4002 34.8001 42.7884 34.8001 40.8002C34.8001 38.812 33.1883 37.2002 31.2001 37.2002C29.2119 37.2002 27.6001 38.812 27.6001 40.8002C27.6001 42.7884 29.2119 44.4002 31.2001 44.4002Z" fill="#FFB700"/>
      <path d="M32.4 15.6366V19.2006C32.4 19.8633 31.8627 20.4006 31.2 20.4006C30.5373 20.4006 30 19.8633 30 19.2006V15.6366C30.396 15.6126 30.792 15.6006 31.2 15.6006C31.608 15.6006 32.004 15.6126 32.4 15.6366Z" fill="#FFB700"/>
      <path d="M32.4 62.4002V65.9642C32.004 65.9882 31.608 66.0002 31.2 66.0002C30.792 66.0002 30.396 65.9882 30 65.9642V62.4002C30 61.7375 30.5373 61.2002 31.2 61.2002C31.8627 61.2002 32.4 61.7375 32.4 62.4002Z" fill="#FFB700"/>
      <path d="M49.848 23.8563L47.316 26.3763C47.0924 26.5979 46.7908 26.7229 46.476 26.7243C46.1576 26.7231 45.8522 26.5983 45.624 26.3763C45.1559 25.9048 45.1559 25.1439 45.624 24.6723L48.144 22.1523C48.732 22.7043 49.296 23.2683 49.848 23.8563Z" fill="#FFB700"/>
      <path d="M16.776 56.928L14.268 59.448H14.256C13.668 58.896 13.104 58.332 12.552 57.744L15.084 55.224C15.3082 54.9993 15.6126 54.873 15.93 54.873C16.2474 54.873 16.5518 54.9993 16.776 55.224C17.244 55.6956 17.244 56.4564 16.776 56.928Z" fill="#FFB700"/>
      <path d="M56.4001 40.8006C56.4001 41.2086 56.3881 41.6046 56.3641 42.0006H52.8001C52.1374 42.0006 51.6001 41.4633 51.6001 40.8006C51.6001 40.1378 52.1374 39.6006 52.8001 39.6006H56.3641C56.3881 39.9966 56.4001 40.3926 56.4001 40.8006Z" fill="#FFB700"/>
      <path d="M10.8 40.8006C10.798 41.4625 10.2619 41.9986 9.6 42.0006H6.036C6.012 41.6046 6 41.2086 6 40.8006C6 40.3926 6.012 39.9966 6.036 39.6006H9.6C10.2619 39.6026 10.798 40.1387 10.8 40.8006Z" fill="#FFB700"/>
      <path d="M49.8479 57.744C49.2959 58.332 48.7319 58.896 48.1439 59.448H48.1319L45.6239 56.928C45.156 56.4564 45.156 55.6956 45.6239 55.224C45.8481 54.9993 46.1525 54.873 46.4699 54.873C46.7873 54.873 47.0917 54.9993 47.3159 55.224L49.8479 57.744Z" fill="#FFB700"/>
      <path d="M16.776 26.3763C16.5478 26.5983 16.2424 26.7231 15.924 26.7243C15.6092 26.7229 15.3076 26.5979 15.084 26.3763L12.552 23.8563C13.104 23.2683 13.668 22.7043 14.256 22.1523L16.776 24.6723C17.244 25.1439 17.244 25.9048 16.776 26.3763Z" fill="#FFB700"/>
      <path d="M43.08 28.92L37.14 34.86C35.5663 33.282 33.4285 32.3966 31.2 32.4V24C35.6559 23.9989 39.9296 25.7688 43.08 28.92Z" fill="#EE5C1C"/>
      <path d="M48 40.8H39.6C39.5949 36.1629 35.837 32.4051 31.2 32.4V24C40.4776 24.0017 47.9983 31.5223 48 40.8Z" fill="#EE5C1C"/>
      <path d="M48 40.8C47.9983 50.0777 40.4776 57.5983 31.2 57.6V49.2C35.8391 49.2 39.6 45.4392 39.6 40.8C39.6 36.1608 35.8391 32.4 31.2 32.4V24C40.4776 24.0017 47.9983 31.5223 48 40.8Z" fill="#FFB700"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.2 66.0006C17.2824 66.0006 6 54.7182 6 40.8006C6 26.883 17.2824 15.6006 31.2 15.6006C45.1176 15.6006 56.4 26.883 56.4 40.8006C56.3842 54.7116 45.111 65.9848 31.2 66.0006Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.6 10.908V6H28.8V10.908" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.4 1.2002H36C37.3255 1.2002 38.4 2.27471 38.4 3.6002V6.0002H24V3.6002C24 2.27471 25.0745 1.2002 26.4 1.2002Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M54 14.4004L50.532 17.8684" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M51.6001 12L56.4001 16.8" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.8679 17.8684L8.3999 14.4004" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.8 12L6 16.8" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2001 44.4002C33.1883 44.4002 34.8001 42.7884 34.8001 40.8002C34.8001 38.812 33.1883 37.2002 31.2001 37.2002C29.2119 37.2002 27.6001 38.812 27.6001 40.8002C27.6001 42.7884 29.2119 44.4002 31.2001 44.4002Z" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 15.6006V19.2006" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 62.4004V66.0004" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M49.0192 22.9814L46.4736 25.527" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9264 56.0732L13.3809 58.6188" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M56.4001 40.7998H52.8" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.6 40.7998H6" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M49.0192 58.6188L46.4736 56.0732" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9264 25.527L13.3809 22.9814" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 57.6002V49.2002" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 32.4V24" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 57.6C40.4783 57.6 48 50.0784 48 40.8C48 31.5216 40.4783 24 31.2 24" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2 49.2004C35.8391 49.2004 39.5999 45.4396 39.5999 40.8004C39.5999 36.1612 35.8391 32.4004 31.2 32.4004" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.58398 45.6001C4.08557 61.0335 18.0208 71.9581 33.6053 70.7033C49.1897 69.4485 61.1975 56.435 61.1975 40.8001C61.1975 25.1652 49.1897 12.1517 33.6053 10.8969C18.0208 9.64203 4.08557 20.5666 1.58398 36.0001" stroke="black" strokeOpacity="0.87" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.2 42.0006C1.86274 42.0006 2.4 41.4633 2.4 40.8006C2.4 40.1378 1.86274 39.6006 1.2 39.6006C0.537258 39.6006 0 40.1378 0 40.8006C0 41.4633 0.537258 42.0006 1.2 42.0006Z" fill="black" fillOpacity="0.87"/>
    </svg>
  );
}

export default Inefficiency;