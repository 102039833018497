import React from 'react';
import {
  Grid,
  Typography,
  Divider
} from '@material-ui/core';

import FormattedNumber from '../FormattedNumber.js';

import pdfIcon from '../../images/download.png';
import videoIcon from '../../images/play-circle.png';
import Graph from '../ContentArea/contentAreaComponents/Graph';

class CategoryResults extends React.Component {
  constructor(props) {
    super();
  }

  render(props) {

    let offerIcon, offerType, offerTitle, offerDescription, offerLink, offerLinkText;

    if ( this.props.industry == 'Manufacturing' ) {
      if ( this.props.category == 'Churn' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/infographics/the-evolution-of-b2b-sales";
          offerTitle = "The Evolution of B2B Sales";
          offerDescription = "Per McKinsey, almost 90% of sales roles have shifted to a virtual model. Learn how enterprising B2B companies are leveraging data science to make the shift to virtual selling seamless and fruitful.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/how-ai-enabled-sales-intelligence-drives-revenue";
          offerTitle = "How AI Enables Sales Intelligence Drives Revenue for Manufacturers";
          offerDescription = "Manufacturers strive to achieve top and bottom line success every quarter, but to do that, they need a strong team of sales reps that can consistently meet or exceed quota. Watch how technology can improve sales performance and minimize administrative or reporting tasks.";
        }
        // Churn subindustry overrides
        if ( this.props.subIndustry == 'Industrial Manufacturing' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/global-manufacturer-drives-organic-growth-with-predictive-sales-analytics-solutions";
          offerTitle = "Global Manufacturer Drives Organic Growth with AI";
          offerDescription = "Learn how this global manufacturer realized a 21 percent increase in customer revenue in just two months, and significant improvement in sales productivity and effectiveness.";
        }
        if ( this.props.subIndustry == 'Fuel & Lubricants Manufacturing' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/specialty-chemicals-manufacturer-realizes-an-incremental-5-million-in-revenue";
          offerTitle = "Specialty Chemicals Manufacturer Realizes an Incremental $5 Million in Revenue";
          offerDescription = "This case study details how this manufacturer increased customer wallet share by delivering highly-qualified cross-sell and retention opportunities to the sales team. The end result? An annualized incremental revenue impact of $5 million.";
        }
        if ( this.props.subIndustry == 'Manufacturing - Other' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/global-manufacturer-drives-organic-growth-with-predictive-sales-analytics-solutions";
          offerTitle = "Global Manufacturer Drives Organic Growth with AI";
          offerDescription = "Learn how this global manufacturer realized a 21 percent increase in same customer revenue in just two months, and significant improvement in sales productivity and effectiveness.";
        }

      } else if ( this.props.category == 'Cross-Sell' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/sales-insights/top-10-revenue-killing-sales-myths-in-b2b";
          offerTitle = "Top 10 Revenue-Killing Sales Myths in B2B";
          offerDescription = "Inadvertently, global sales organizations have bought into, and held onto, a series of myths about sales effectiveness that have hindered their ability to grow sales and reduce churn.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/how-ai-improves-sales-performance-and-productivity";
          offerTitle = "How AI Improves Sales Performance & Productivity";
          offerDescription = "Learn why existing customers are your most effective path to revenue growth and how AI and data science can improve the performance and productivity of your sales team.";
        }
      } else if ( this.props.category == 'Inconsistency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/reimagine-global-and-country-price-list-management";
          offerTitle = "Reimagine Global and Country List Price Management";
          offerDescription = "See how Zilliant enables multi-national manufacturers to overcome cumbersome pricing processes to more efficiently update, maintain and strategically manage global and country list prices.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/finding-and-fixing-margin-leakage-in-customer-specific-pricing";
          offerTitle = "Finding & Fixing Margin Leakage in Customer-Specific Pricing";
          offerDescription = "Learn what it takes in terms of methodology, tools, process and communication to set and update customer-specific pricing that improves margin and satisfies customers.";
        }

        // Inconsistency subindustry overrides
        if ( this.props.subIndustry == 'Chemicals Manufacturing' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/pricing-insights/improving-formula-pricing-in-specialty-chemicals-manufacturing";
          offerTitle = "Dynamic Formula Pricing in Specialty Chemicals Manufacturing";
          offerDescription = "Tackle unit of measure complications and pricing of systems, and automate formula price calculations with pricing software capable of addressing data challenges and business complexities.";
        }
        if ( this.props.subIndustry == 'Building Products Manufacturing' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/terreal-overcomes-complexity-to-improve-prices";
          offerTitle = "Terreal Overcomes Complexity to Improve Prices";
          offerDescription = "Learn how Terreal outperformed the market in terms of price, margin and volume with price optimization.";
        } else if ( this.props.subIndustry == 'Electrical Products Manufacturing' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/schneider-implements-pricing-optimization-software";
          offerTitle = "Schneider Electric Realizes Margin Improvement from Price Optimization";
          offerDescription = "Learn how this electrical products manufacturer automates price quoting and optimizes prices to better match market conditions and customer expectations.";
        } else if ( this.props.subIndustry == 'Electronic Component & High-Tech Manufacturing' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/tsia-optimize-the-land-expand-adopt-renew-process-with-data-science";
          offerTitle = "[TSIA] Optimize the Land-Expand-Adopt-Renew Process with Data Science.";
          offerDescription = "Watch this Zilliant + Schneider Electric + TSIA webinar to learn how global technology firms are leveraging data science-driven pricing and sales solutions to analyze historical sales patterns and data to help their sales organizations and their channel partners.";
        } else if ( this.props.subIndustry == 'Food Producers' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/price-modes-updating-price-lists";
          offerTitle = "Price Modes: Setting a Standard and updating price lists across countries.";
          offerDescription = "This on-demand webinar details the four major pricing modes: List Prices, Matrix Pricing, Customer-Specific Agreements, and Spot Pricing, and discusses the inherent challenges and best ways to address them.";
        }

      } else if ( this.props.category == 'Misalignment' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/the-art-of-intelligent-automated-negotiation";
          offerTitle = "Intelligent Automated Negotiation: Change the Price Negotiation Game";
          offerDescription = "This whitepaper discusses the challenges inherent with the common negotiation process and how leading companies are bringing science to the art of negotiation to significantly reduce bulky processes and reserve high-skilled negotiators for the most strategic deals.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/rational-pricing-meet-and-beat-expectations";
          offerTitle = "Rational Pricing: What It Is and How it Helps Beat Expectations";
          offerDescription = "Read this whitepaper to find out how misaligned and irrational prices negatively impact profitability, and more importantly, what steps can be taken to get rational prices to market.";
        }
      } else if ( this.props.category == 'Inefficiency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/real-time-pricing-strategy";
          offerTitle = "Real Time Pricing Strategy";
          offerDescription = "Learn how to dynamically enable real-time price updates when you need to react quickly to pricing triggers.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/the-road-to-price-management";
          offerTitle = "The Road to Price Management";
          offerDescription = "Outsmart the competition with the price management tools that power the most efficient and effective cost pass-through strategies.";
        }
      }
    } else if ( this.props.industry == 'Distribution' ) {
      if ( this.props.category == 'Churn' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/infographics/the-evolution-of-b2b-sales";
          offerTitle = "The Evolution of B2B Sales";
          offerDescription = "Per McKinsey, almost 90% of sales roles have shifted to a virtual model. Learn how enterprising B2B companies are leveraging data science to make the shift to virtual selling seamless and fruitful.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/proapro-adopts-salesiq";
          offerTitle = "Pro a Pro Grows Wallet Share with Actionable Customer Insights";
          offerDescription = "Pro a Pro Chief Executive Officer Guillaume Deruyter shares how he overcame the challenges of an intensely complex and competitive environment to deliver actionable insights to reps that highlight cross-sell and recovery insights across the customer base.";
        }

        // Churn subindustry overrides
        if ( this.props.subIndustry == "Food Service Distribution" ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/metro-france-empowers-sales-team";
          offerTitle = "Metro France Empowers Sales Team with Zilliant";
          offerDescription = "Learn why foodservice distributor METRO France chose to partner with Zilliant to empower its sales team to get the right prices to the right customers at the right time, while ensuring price consistency and relevant product recommendations across channels.";
        }
        if ( this.props.subIndustry == "MRO Distribution" ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/mro-distributor-reduces-customer-churn-grows-revenue-more-than-60million";
          offerTitle = "MRO Distributor Reduces Customer Churn";
          offerDescription = "Market share in MRO distribution is often threatened from many directions; read how one company equipped reps to retain and grow same customer revenue.";
        }
        if ( this.props.subIndustry == "Electronic Components & High-Tech Distribution" ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/electronic-components-distributor-improves-sales-productivity";
          offerTitle = "Electronic Components Distributor Improves Sales Productivity with Prescriptive Analytics";
          offerDescription = "This electronic components distributor gave its sales reps guidance about where their time was best spent and which products to offer, resulting in elevated sales performance across the organization and better customer experiences.";
        }

      } else if ( this.props.category == 'Cross-Sell' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/sales-insights/top-10-revenue-killing-sales-myths-in-b2b";
          offerTitle = "Top 10 Revenue-Killing Sales Myths in B2B";
          offerDescription = "Inadvertently, global sales organizations have bought into, and held onto, a series of myths about sales effectiveness that have hindered their ability to grow sales and reduce churn.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/how-ai-improves-sales-performance-and-productivity";
          offerTitle = "How AI Improves Sales Performance & Productivity ";
          offerDescription = "Learn why existing customers are your most effective path to revenue growth and how AI and data science can improve the performance and productivity of your sales team.";
        }
      } else if ( this.props.category == 'Inconsistency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/reimagine-global-and-country-price-list-management";
          offerTitle = "Reimagine Global and Country List Price Management";
          offerDescription = "See how Zilliant enables distributors to overcome cumbersome pricing processes to more efficiently update, maintain and strategically manage list prices.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/finding-and-fixing-margin-leakage-in-customer-specific-pricing";
          offerTitle = "Finding & Fixing Margin Leakage in Customer-Specific Pricing";
          offerDescription = "Learn what it takes in terms of methodology, tools, process and communication to set and update customer-specific pricing that improves margins and satisfies customers.";
        }

        // Inconsistency overrides
        if ( this.props.subIndustry == 'Metals' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/weather-the-tariff-storm-with-price-optimization";
          offerTitle = "Weather the Tariff Storm with Price Optimization";
          offerDescription = "Why you need an intelligent pricing solution now more than ever.";
        }
        if ( this.props.subIndustry == 'Chemicals Distribution' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/pricing-reimagined-commercial-excellence-to-deliver-profitable-growth-in-chemical-industries";
          offerTitle = "Nexeo Plastics' Pricing and Business Transformation Journey";
          offerDescription = "Learn how Nexeo Plastics is transforming its approach to price and deal management.";
        }
        if ( this.props.subIndustry == 'Fuel & Lubricants Distribution' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/enabling-more-profitable-pricing-in-wholesale-distribution";
          offerTitle = "Enabling More Profitable Pricing in Distribution";
          offerDescription = "Distributors face massive complexity; reimagine a holistic solution that addresses the root causes of margin leakage with this whitepaper.";
        }
        if ( this.props.subIndustry == 'Automotive & Truck Parts Distribution' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/embracing-change-in-the-heavy-duty-parts-market";
          offerTitle = "Embracing Change in the Heavy-Duty Parts Market";
          offerDescription = "Download the eBook and learn how using data science can unlock powerful insights that exist within your customer and transaction history.";
        }
        if ( this.props.subIndustry == 'Electrical Products Distribution' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/electrical-products-distribution-playbook-for-digital-immunity";
          offerTitle = "Electrical Products Distribution Playbook for Digital Immunity";
          offerDescription = "The modern electrical products customer is buying online. Here’s how to attract them to you (instead of Amazon) without giving away your profits.";
        }
        if ( this.props.subIndustry == 'Building Products Distribution' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/reimagine-pricing-quoting-and-bids";
          offerTitle = "Pricing Playbook for Building Products Distributors";
          offerDescription = "Halt excessive price deviations by providing a relevant system price, reduce over discounting with discount guardrails and more effectively manage customer price exceptions.";
        }
        if ( this.props.subIndustry == 'Distribution - Other' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/enabling-more-profitable-pricing-in-wholesale-distribution";
          offerTitle = "Enabling More Profitable Pricing in Wholesale Distribution";
          offerDescription = "Wholesale distributors face massive complexity; reimagine a holistic solution that addresses the root causes of margin leakage with this whitepaper.";
        }

      } else if ( this.props.category == 'Misalignment' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/mdm-spotlight-real-time-market-pricing-for-ecommerce-distribution";
          offerTitle = "Real-Time Market Pricing for eCommerce Distribution";
          offerDescription = "Digitization fundamentally creates more data and a faster rate of change for distributors. Learn how to leverage your datat to set and maintain a coherent pricing strategy in a rapidly fluctuating market.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/spandex-adopts-zilliant-pricing-and-sales-solutions-to-improve-business-performance";
          offerTitle = "Spandex Adopts Pricing and Sales Solutions";
          offerDescription = "Learn how Europe’s leading B2B provider of signs, displays, digital prints and specialty graphics, improved business performance with Zilliant pricing and sales solutions.";
        }
      } else if ( this.props.category == 'Inefficiency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/real-time-pricing-strategy";
          offerTitle = "Real Time Pricing Strategy";
          offerDescription = "Learn how to dynamically enable real-time price updates when you need to react quickly to pricing triggers.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/the-road-to-price-management";
          offerTitle = "The Road to Price Management";
          offerDescription = "Outsmart the competition with the price management tools that power the most efficient and effective cost pass-through strategies.";
        }
      }
    } else if ( this.props.industry == 'Other' ) {
      if ( this.props.category == 'Churn' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/infographics/the-evolution-of-b2b-sales";
          offerTitle = "The Evolution of B2B Sales";
          offerDescription = "Per McKinsey, almost 90% of sales roles have shifted to a virtual model. Learn how enterprising B2B companies are leveraging data science to make the shift to virtual selling seamless and fruitful.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/videos-podcasts/proapro-adopts-salesiq";
          offerTitle = "Pro a Pro Grows Wallet Share with Actionable Customer Insights";
          offerDescription = "Pro a Pro Chief Executive Officer Guillaume Deruyter shares how he overcame the challenges of an intensely complex and competitive environment to deliver actionable insights to reps that highlight cross-sell and recovery insights across the customer base.";
        }

        // Churn subindustry overrides
        if ( this.props.subIndustry == 'Staffing Services' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/how-price-optimization-helps-b2b-sales";
          offerTitle = "How Price Optimization Helps B2B Sales";
          offerDescription = "Learn how price optimization enables B2B sales reps to improve customer win rates, reduce quote turnaround time and sell more profitable deals.";
        }

      } else if ( this.props.category == 'Cross-Sell' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/sales-insights/top-10-revenue-killing-sales-myths-in-b2b";
          offerTitle = "Top 10 Revenue-Killing Sales Myths in B2B";
          offerDescription = "Inadvertently, global sales organizations have bought into, and held onto, a series of myths about sales effectiveness that have hindered their ability to grow sales and reduce churn.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/how-ai-improves-sales-performance-and-productivity";
          offerTitle = "How AI Improves Sales Performance & Productivity";
          offerDescription = "Learn why existing customers are your most effective path to revenue growth and how AI and data science can improve the performance and productivity of your sales team.";
        }
      } else if ( this.props.category == 'Inconsistency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/reimagine-global-and-country-price-list-management";
          offerTitle = "Reimagine Global and Country List Price Management";
          offerDescription = "See how Zilliant enables multi-national companies to overcome cumbersome pricing processes to more efficiently update, maintain and strategically manage global and country list prices.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/finding-and-fixing-margin-leakage-in-customer-specific-pricing";
          offerTitle = "Finding & Fixing Margin Leakage in Customer-Specific Pricing";
          offerDescription = "Learn what it takes in terms of methodology, tools, process and communication to set and update customer-specific pricing that improves margin and satisfies customers.";
        }

        // Inconsistency subindustry overrides
        if ( this.props.subIndustry == 'Equipment Rental' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/case-studies/rental-company-transforms-business-increases-revenue";
          offerTitle = "Rental Company Transforms Business, Increases Revenue More than 15 Percent";
          offerDescription = "Learn how Zilliant enables rental companies to deliver market-aligned rates across day/week/month spreads, align cat classes and account for specific regional and rate zone disparities.";
        }
        if ( this.props.subIndustry == 'Shipping & Transportation' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/part-1-driving-margin-operational-benefits-for-shipping-providers";
          offerTitle = "Driving Margin & Operational Improvement for Shipping Providers";
          offerDescription = "Get groundbreaking online self-service functionality guided by micro-segmented shipping profiles and optimized shipping rates to auto-negotiate with customers within margin targets.";
        }
        if ( this.props.subIndustry == 'Services - Other' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/top-10-margin-killing-myths-about-b2b-pricing";
          offerTitle = "The Top 10 Margin-Killing Myths About B2B Pricing";
          offerDescription = "Inadvertently, B2B organizations have bought into, and held onto, a series of myths about pricing that have hindered their ability to grow margin.";
        }

      } else if ( this.props.category == 'Misalignment' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/the-art-of-intelligent-automated-negotiation";
          offerTitle = "Intelligent Automated Negotiation: Change the Price Negotiation Game";
          offerDescription = "This whitepaper discusses the challenges inherent with the common negotiation process and how leading companies are bringing science to the art of negotiation to significantly reduce bulky processes and reserve high-skilled negotiators for the most strategic deals.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/rational-pricing-meet-and-beat-expectations";
          offerTitle = "Rational Pricing: What It Is and How it Helps Beat Expectations";
          offerDescription = "Read this whitepaper to find out how misaligned and irrational prices negatively impact profitability, and more importantly, what steps can be taken to get rational prices to market.";
        }
      } else if ( this.props.category == 'Inefficiency' ) {
        if ( this.props.highLowResultForCat == 'Low' ) {
          offerType = "video";
          offerLink = "https://resources.zilliant.com/webinars/real-time-pricing-strategy";
          offerTitle = "Real Time Pricing Strategy";
          offerDescription = "Learn how to dynamically enable real-time price updates when you need to react quickly to pricing triggers.";
        } else if ( this.props.highLowResultForCat == 'High' ) {
          offerType = "pdf";
          offerLink = "https://resources.zilliant.com/whitepapers/the-road-to-price-management";
          offerTitle = "The Road to Price Management";
          offerDescription = "Outsmart the competition with the price management tools that power the most efficient and effective cost pass-through strategies.";
        }
      }
    }


    if ( offerType == 'pdf' ) {
      offerIcon = pdfIcon;
      offerLinkText = "Download PDF";
    } else if ( offerType == 'video' ) {
      offerIcon = videoIcon;
      offerLinkText = "Watch Video";
    }
    let selectHighLow = this.props.highLowResultForCat
    let highLowClass = ''

    if(selectHighLow=="High"){
      highLowClass = 'category-score-text category-score-high'
    }
    else if (selectHighLow=="Low"){
      highLowClass = 'category-score-text category-score-low'
    }


    let profitLossCopy = 'Profit Loss Potential';
    let percentileComparisonCopy = 'Percentile Comparison';

    if ( this.props.category == 'Churn' ) {
      profitLossCopy = 'Revenue Loss From Customer Churn';
      percentileComparisonCopy = 'Customer Churn Percentile Comparison';
    } else if ( this.props.category == 'Cross-Sell' ) {
      profitLossCopy = 'Revenue Loss From Missed Cross-Sell';
      percentileComparisonCopy = 'Missed Cross-Sell Percentile Comparison';
    } else if ( this.props.category == 'Inconsistency' ) {
      profitLossCopy = 'Margin Loss From Inconsistent Pricing';
      percentileComparisonCopy = 'Inconsistent Pricing Percentile Comparison';
    } else if ( this.props.category == 'Misalignment' ) {
      profitLossCopy = 'Margin Loss From Misaligned Market Pricing';
      percentileComparisonCopy = 'Misaligned Market Pricing Percentile Comparison';
    } else if ( this.props.category == 'Inefficiency' ) {
      profitLossCopy = 'Margin Loss From Inefficient Pricing Practices';
      percentileComparisonCopy = 'Inefficient Pricing Practices Percentile Comparison';
    }



    return(
      <div className="card">
        <Grid container spacing={3}>

          <Grid item xs={2}>
            <div>
              {this.props.icon}
            </div>
          </Grid>

          <Grid item xs={10}>
            <Typography variant="h2">
              {this.props.title}
            </Typography>

            <p>
              {this.props.description}
            </p>

            <Divider/>

            <p style={{marginTop: '40px', marginBottom: '8px', color: 'rgba(0, 0, 0, 0.54)'}}>
              {profitLossCopy}
            </p>

            <Typography variant="h5">
              <FormattedNumber
                number={this.props.totalProfitLossPotential}
                currency={this.props.currency}
              />
            </Typography>

            
            <div style={{position: 'relative', marginBottom: '75px'}}>
              <p style={{marginTop: '33px', maxWidth: '80%', color: 'rgba(0, 0, 0, 0.54)'}}>
                {percentileComparisonCopy}
              </p>

              <div className={highLowClass}>
                {selectHighLow}
              </div>
            </div>


            <div style={{margin: '20px 0 24px'}}>

              <Graph
                category={this.props.category}
                industry={this.props.industry}
                score={this.props.score}
              />
                
            </div>

          </Grid>

        </Grid>


        <Divider className="full-width-divider" />


        <Grid
          container
          className="content-download-wrapper"
          component="a"
          target="_blank"
          href={offerLink}
          spacing={3}
        >

          <Grid item xs={2}>
            <img src={offerIcon} />
          </Grid>

          <Grid item xs={10}>
            <Typography variant="h6">
              {offerLinkText}
            </Typography>

            <p>
              {offerDescription}
            </p>
          </Grid>

        </Grid>
      </div>
    );
  }
}


export default CategoryResults;
