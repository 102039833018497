import React from 'react';
import numeral from 'numeral';

function FormattedNumber(props) {
  let currency = props.currency || 'USD';
  let number = Number(props.number);
  let formattedNumber = number;

  // for future use if we re-implement the currency conversion toggle
  // if ( currency == 'EUR' ) {
  //   fetch(`https://api.exchangeratesapi.io/latest?symbols=EUR&base=USD`)
  //   .then(response => response.json())
  //   .then(data => {
  //     const rate = data['rates']['EUR'];
  //     number = number * rate;
  //   });
  // }
  

  if ( number > 99999 ) { // show abbreviated number for large numbers
    if ( currency == 'EUR' ) {
      formattedNumber = '€' + numeral(number).format('0.0a').toUpperCase();
    } else {
      formattedNumber = '$' + numeral(number).format('0.0a').toUpperCase();
    }
  } else { // show full number for small numbers
    if ( currency == 'EUR' ) {
      formattedNumber = new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'EUR' }).format(number);
    } else {
      formattedNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
    }
    formattedNumber = formattedNumber.substring(0, formattedNumber.length -3);
  }

  return(
    <>
      {formattedNumber}
    </>
  );
}

export default FormattedNumber;