import React from 'react';


function Misalignment() {
  return(
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M44.015 37.7C42.965 41.2772 45.407 45.05 49.3478 45.3668C51.7478 45.56 54.0926 44.198 55.1378 42.0284C56.2358 39.7496 55.787 37.2536 54.3398 35.5316L62.4278 37.9076C64.1402 38.4104 65.9366 37.43 66.4394 35.7176L68.8154 27.6284C67.0934 29.0756 64.5986 29.5244 62.3198 28.4276C60.1502 27.3812 58.7882 25.0376 58.9802 22.6376C59.297 18.6956 63.071 16.2536 66.647 17.3048C68.6474 17.8916 70.1054 19.4312 70.6802 21.2828L72.0026 13.2728C72.0026 10.4228 71.9834 10.8548 70.8026 9.67275L62.6786 6.42436C60.5114 5.55676 58.0466 6.55156 57.0878 8.68036L44.015 37.7Z" fill="#FFB700"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6021 70.8729H35.7153C37.8621 70.8729 39.6021 69.0897 39.6021 66.8889V59.1429C38.3277 60.3141 36.6489 61.0329 34.8021 61.0329C30.8253 61.0329 27.6021 57.7293 27.6021 53.6529C27.6021 49.5765 30.8253 46.2729 34.8021 46.2729C36.6489 46.2729 38.3277 46.9917 39.6021 48.1629V39.1869C39.6021 36.9861 37.8621 35.2029 35.7153 35.2029H26.7789C28.7204 33.8805 30.0021 31.6209 30.0021 29.0529C30.0021 24.9765 26.7789 21.6729 22.8021 21.6729L21.6021 70.8729Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M64.4158 8.17285C66.1282 8.67565 67.1086 10.4721 66.6058 12.1845L65.5378 15.8097C65.2162 14.7741 64.2958 14.9337 63.1978 15.1725C63.4918 15.2121 63.7858 15.2517 64.0798 15.3381C66.079 15.9249 67.5382 17.4644 68.1118 19.3161L70.2058 12.1845C70.7086 10.4721 69.7282 8.67565 68.0158 8.17285H64.4158Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M61.9382 27.8111L60.2726 33.7511C60.029 34.5779 59.4818 35.2271 58.793 35.6279L59.8598 35.9423C61.5734 36.4451 63.3686 35.4647 63.8726 33.7511L66.2474 25.6631C64.763 26.9099 62.7038 27.4163 60.7046 26.8283L61.9382 27.8111Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.2026 45.6729C33.0494 45.6729 34.7282 46.3737 36.0026 47.5173V38.7597C36.0026 36.6129 34.2626 34.8729 32.1158 34.8729H23.1794C25.121 33.5829 26.4026 31.3785 26.4026 28.8729C26.4026 24.8961 23.1794 21.6729 19.2026 21.6729C15.2258 21.6729 12.0026 24.8961 12.0026 28.8729C12.0026 31.3785 13.2842 33.5829 15.2258 34.8729H5.08944C2.94264 34.8729 1.20264 36.6129 1.20264 38.7597V48.8961C2.49264 46.9545 4.69704 45.6729 7.20264 45.6729C11.1794 45.6729 14.4026 48.8961 14.4026 52.8729C14.4026 56.8497 11.1794 60.0729 7.20264 60.0729C4.69704 60.0729 2.49264 58.7913 1.20264 56.8497V65.7861C1.20264 67.9329 2.94264 69.6729 5.08944 69.6729H32.1158C34.2626 69.6729 36.0026 67.9329 36.0026 65.7861V58.2285C34.7282 59.3721 33.0494 60.0729 31.2026 60.0729C27.2258 60.0729 24.0026 56.8497 24.0026 52.8729C24.0026 48.8961 27.2258 45.6729 31.2026 45.6729Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M31.2032 45.6729C33.05 45.6729 34.7288 46.3737 36.0032 47.5173V38.7597C36.0032 36.6129 34.2632 34.8729 32.1164 34.8729H23.18C25.1216 33.5829 26.4032 31.3785 26.4032 28.8729C26.4032 24.8961 25.58 21.6729 21.6032 21.6729C17.6264 21.6729 15.6032 24.8961 15.6032 28.8729C15.6032 31.3785 16.8848 33.5829 18.8264 34.8729H8.69002C6.54322 34.8729 4.80322 36.6129 4.80322 38.7597V46.8729C6.09322 44.9313 8.29762 45.6729 10.8032 45.6729C14.78 45.6729 18.0032 48.8961 18.0032 52.8729C18.0032 56.8497 14.78 60.0729 10.8032 60.0729C8.29762 60.0729 6.09322 60.8145 4.80322 58.8729V65.7861C4.80322 67.9329 6.54322 69.6729 8.69002 69.6729H33.6032C35.75 69.6729 36.0032 67.9329 36.0032 65.7861V58.2285C34.7288 59.3721 33.05 60.0729 31.2032 60.0729C27.2264 60.0729 24.0032 56.8497 24.0032 52.8729C24.0032 48.8961 27.2264 45.6729 31.2032 45.6729Z" fill="#EE5C1C"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M33.1049 18.5347C35.1041 19.1215 37.1633 18.6163 38.6477 17.3695C38.5229 17.7931 38.4125 18.1699 38.3249 18.4699L36.2717 25.4575C35.7689 27.1699 39.5069 28.9663 41.2193 29.4691L45.0473 31.5643C43.1957 32.1379 41.6561 33.5971 41.0693 35.5963C40.1369 38.7691 41.9537 42.0967 45.1265 43.0291C48.2993 43.9603 51.6269 42.1435 52.5593 38.9707C53.1461 36.9715 52.6409 34.9123 51.3929 33.4279L56.7245 35.8039C58.4381 36.3067 60.2333 35.3263 60.7373 33.6139L62.4029 27.6727C60.2285 26.7727 60.4637 26.5315 58.8029 25.2727C56.3285 23.3983 56.6705 22.4311 57.6029 19.2583C58.5353 16.0855 57.7529 14.9227 60.9449 15.1999C63.0209 15.3811 63.5285 14.6983 66.0029 15.6727L67.0709 12.0475C67.5737 10.3351 66.5933 8.53869 64.8809 8.03589L46.8029 1.70109C45.0905 1.19829 43.1093 2.17869 42.6065 3.89109L40.8353 9.92229C40.7393 10.2475 40.6301 10.6219 40.5125 11.0227C39.9377 9.17109 38.4797 7.63149 36.4793 7.04469C33.3065 6.11229 29.9789 7.92909 29.0477 11.1019C28.1153 14.2747 29.9321 17.6023 33.1049 18.5347Z" fill="#FFB700"/>
      <path d="M18.0026 69.6729H32.1158C34.2626 69.6729 36.0026 67.9329 36.0026 65.7861V58.2285C34.7282 59.3721 33.0494 60.0729 31.2026 60.0729C27.2258 60.0729 24.0026 56.8497 24.0026 52.8729C24.0026 48.8961 27.2258 45.6729 31.2026 45.6729C33.0494 45.6729 34.7282 46.3737 36.0026 47.5173V38.7597C36.0026 36.6129 34.2626 34.8729 32.1158 34.8729H23.1794C25.121 33.5829 26.4026 31.3785 26.4026 28.8729C26.4026 24.8961 23.1794 21.6729 19.2026 21.6729C15.2258 21.6729 12.0026 24.8961 12.0026 28.8729C12.0026 31.3785 13.2842 33.5829 15.2258 34.8729H5.08944C2.94264 34.8729 1.20264 36.6129 1.20264 38.7597V48.8961C2.49264 46.9545 4.69704 45.6729 7.20264 45.6729C11.1794 45.6729 14.4026 48.8961 14.4026 52.8729C14.4026 56.8497 11.1794 60.0729 7.20264 60.0729C4.69704 60.0729 2.49264 58.7913 1.20264 56.8497V65.7861C1.20264 67.9329 2.94264 69.6729 5.08944 69.6729H8.40264" stroke="black" strokeOpacity="0.87" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M52.8 3.43028L46.914 1.70108C45.2016 1.19828 43.4052 2.17868 42.9024 3.89228L41.1312 9.92228C41.0352 10.2475 40.926 10.6219 40.8084 11.0227C40.2336 9.17108 38.7756 7.63148 36.7752 7.04468C33.6024 6.11228 30.2748 7.92908 29.3436 11.1019C28.4112 14.2759 30.228 17.6035 33.4008 18.5347C35.4 19.1227 37.4592 18.6163 38.9436 17.3695C38.8188 17.7931 38.7084 18.1711 38.6208 18.4699L36.5676 25.4575C36.0648 27.1699 37.0452 28.9663 38.7576 29.4691L45.8892 31.5643C44.0376 32.1379 42.498 33.5971 41.9112 35.5963C40.9788 38.7691 42.7956 42.0967 45.9684 43.0291C49.1424 43.9603 52.47 42.1447 53.4012 38.9707C53.9892 36.9715 53.4828 34.9123 52.236 33.4279L60.324 35.8039C62.0376 36.3067 63.8328 35.3263 64.3356 33.6139L66.7116 25.5247C65.2272 26.7727 63.168 27.2779 61.1688 26.6911C57.996 25.7587 56.1792 22.4311 57.1116 19.2583C58.0428 16.0855 61.3704 14.2687 64.5432 15.2011C66.5436 15.7879 68.0016 17.3275 68.5764 19.1791L70.6704 12.0475C71.1732 10.3351 70.1928 8.53868 68.4804 8.03588L60.006 5.54708" stroke="black" strokeOpacity="0.87" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M57.6378 4.68828C57.6378 4.02588 57.1002 3.48828 56.4378 3.48828C55.7754 3.48828 55.2378 4.02588 55.2378 4.68828C55.2378 5.35068 55.7754 5.88828 56.4378 5.88828C57.1002 5.88828 57.6378 5.35068 57.6378 4.68828Z" fill="black" fillOpacity="0.87"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.4029 69.6736C14.4029 69.0112 13.8653 68.4736 13.2029 68.4736C12.5405 68.4736 12.0029 69.0112 12.0029 69.6736C12.0029 70.336 12.5405 70.8736 13.2029 70.8736C13.8653 70.8736 14.4029 70.336 14.4029 69.6736Z" fill="black" fillOpacity="0.87"/>
      </g>
      <defs>
      <clipPath id="clip0">
      <rect width="72" height="72" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default Misalignment;