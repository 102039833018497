import React from 'react';
import {
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@material-ui/core';

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value:1
    }
  }


  render() {

    return(
      <>
        <Typography variant="h4" style={{marginBottom: '24px'}}>
          {this.props.question}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup  onChange={this.props.setQuestion} value = {String(this.props.radioSelect)} >
            <FormControlLabel value={'1'} control={<Radio />} label={'Strongly Disagree'} />
            <FormControlLabel value={'2'} control={<Radio />} label={'Disagree'} />
            <FormControlLabel value={'3'} control={<Radio />} label={'Neutral'} />
            <FormControlLabel value={'4'} control={<Radio />} label={'Agree'} />
            <FormControlLabel value={'5'} control={<Radio />} label={'Strongly Agree'} />
          </RadioGroup>
        </FormControl>
      </>
    );
  }
}

export default Question;
