import React from 'react';
import {
  Typography,
  Grid,
  Divider
} from '@material-ui/core';

import dollarIcon from '../../../../images/dollar-icon.png';
import percentIcon from '../../../../images/percent-icon.png';

import Graph from '../Graph.js';

function GraphSelect(props) {

  if(props.category=='Churn'){
  return(
    <Graph
      industry={props.industry}
      category={props.category}
      score={props.churnScore}
    />
  );}
  else if (props.category=='Cross-Sell'){
    return(
      <Graph
        industry={props.industry}
        category={props.category}
        score={props.crossSellScore}
      />);
  }
  else if (props.category=='Inconsistency'){
    return(
      <Graph
        industry={props.industry}
        category={props.category}
        score={props.inconsistencyScore}
      />);
  }
  else if (props.category=='Misalignment'){
    return(
      <Graph
        industry={props.industry}
        category={props.category}
        score={props.misalignmentScore}
      />);
  }
  else if (props.category=='Inefficiency'){
    return(
      <Graph
        industry={props.industry}
        category={props.category}
        score={props.inefficiencyScore}
      />);
  }

}

export default GraphSelect;
