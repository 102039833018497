// import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import './App.css';


import Routing from './routes/routing.js';



function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Routing/>
    </ThemeProvider>
  );
}

export default App;
